.col_pfml-sidebar{
  .pfml-sidebar {
    &-device_title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.2px;
      color: #cce5ff;
      padding-left: 12px;
      padding-right: 12px;
      position: relative;
      display: block;
      padding-top: 5px;
      padding-bottom: 5px;
      background: #424242;
    }
    &_tabs_wraper{
      &-tab {
        max-height: 30px;
        color: rgba(255, 255, 255, 0.5) !important;
        line-height: 13px;

        &-input {
          width: 106px;
        }

        &-input_number_label {
          font-family: $fontRoboto;
          font-weight: 500 !important;
          font-size: 14px !important;
        }

        &-input_number {
          border: 1px solid rgba(211, 213, 220, 0.3);
          box-sizing: border-box;
          width: 40px;
          height: 20px;
          position: relative;
          // left: -121px;
        }

        &.active {
          background: #535353 !important;
          font-family: $fontRoboto !important;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
          letter-spacing: 0.2px;
          color: #e8e8e8 !important;
          border-top: 0px solid #353434 !important;
          border-left: 1px solid #353434 !important;
          border-right: 1px solid #353434 !important;
          border-bottom: 1px solid #535353 !important;
          border-radius: 0px !important;

          &:first-child {
            border-left: 0px solid #353434 !important;
          }
        }
      }
    }
    &-more_details{
      padding: .5em;
      text-align: center;
      &-text_help{
        color: $colorForSidebarText;
        opacity: 0.3;
        color: #E8E8E8;
        font-family: $fontRobotoCondensed;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
      }
      & .button__option-menu{
        margin: 1em auto;
      }
    }
  }
  & .tab-content{
    margin-top: 1em;
  }
  & .sidebar-modal{
    border:none;
  }
  & .content-border-line{
    margin-top: 0;
  }
}

