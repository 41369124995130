.loader {
  &-wrap {
    position: relative;
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
