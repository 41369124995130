@import "variables/index.scss";
@import "components/header/index.scss";
@import "global/index.scss";
@import "components/sidebar/index.scss";
@import "components/content/index.scss";
@import "components/modal/index.scss";
@import "components/context_menu/index.scss";
@import "components/color_picker/index.scss";
@import "components/personal_page/index.scss";
@import "components/Table/index.scss";
@import "components/projectDetails/index.scss";
@import "components/defaultComponents/index.scss";
@import "components/authorizations/index.scss";
@import "components/svgAnimations/index.scss";
@import "components/vls/index.scss";
@import "components/ToolPanel/index.scss";
@import "components/error/index.scss";
@import "components/default_page_layout/index.scss";
@import "components/NewsHomePage/index.scss";
@import "components/Paginations/index.scss";
@import "components/error_page/index.scss";
@import "components/details_news/index.scss";
@import "components/faq_page/index.scss";
@import "components/loader/index.scss";
@import "components/modal_canvas/index.scss";
@import "components/modal_gltf_loader/index.scss";
@import "components/tooltip_vls/index.scss";
@import "components/personalArea/index.scss";
@import "components/tooltip_panel_device/index.scss";
@import "components/addProjectPersonalPage/index.scss";
@import "components/lightTheme/index.scss";
@import "components/pfml/index.scss";
@import "components/Landing_page/index.scss";
@import "fonts/fonts.scss";
@import "~bootstrap/scss/bootstrap";
@import "components/pfml/index.scss";
@import 'components/layoutForPFMLForSettings/index.scss';
@import "components/sidebarPFML/index.scss";
@import "components/tracker/index.scss"