.light {
  background: $lightThemeBody;
  color: $black;
  //header in vls
  & .vls-drop-menu-vls {
    background-image: url("../../../../assets/images/menu_light-theme.png");
  }
  .vls-row_header {
    & .vls-header-menu-active {
      & ul {
        background: $lightThemeHeader;
      }
    }
    background: $lightThemeHeader;
    & li {
      color: black;
    }
    & .vls-header-project_info-text {
      color: #25282b;
    }
    & .vls-header-project_info-project_name {
      color: #25282b;
      &::after {
        background-image: url("../../../../assets/images/arrowDown.png");
      }
    }
    & .vls-header-logout {
      background-image: url("../../../../assets/images/logout_white.png");
    }
  }
  // toolpanel in vls
  .col_vls-header-menu_left {
    background: $lightThemeBody;
    border-right: 2px solid $borderWhiteTheme;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    & .tool_panel-elements {
      &:hover {
        &::before {
          content: "";
          position: absolute;
          width: 35px;
          height: 35px;
          margin: 3px 4px 5px 5px;
          background: $lightThemeInput;
          box-shadow: 3px 3px 6px $lightThemeInput, -3px -3px 6px $w;
          box-sizing: border-box;
          border-radius: 2px;
        }
      }
      &-btn:focus::before {
        box-shadow: 3px 3px 6px #d4d4d8, -3px -3px 6px $w;
        background: $lightThemeInput;
      }
      & .tool_panel-add_element_select {
        background: $lightThemeBody;
        color: $black;
      }
    }
    & .tool_panel-controler-line {
      background: $borderWhiteTheme;
    }
    & .tool_panel-controler-on_and_off_btn {
      background: $lightThemeBlocks;
      box-shadow: 3px 3px 6px #ececef, -3px -3px 6px #ffffff;
      position: relative;
    }
    & .off {
      background-size: auto;
      background-position: center;
      background-image: url("../../../../assets/images/off_white.svg");
    }
    & .on {
      background-image: url("../../../../assets/images/on_white.svg");
    }
    & .play {
      background-image: url("../../../../assets/images/play_white.svg");
      background-size: cover;
      box-shadow: 3px 3px 6px #ececef, -3px -3px 6px #ffffff;
    }
    & .pause {
      background-image: url("../../../../assets/images/paused_white.svg");
      background-size: auto;
      box-shadow: 3px 3px 6px #ececef, -3px -3px 6px #ffffff;
    }
  }
  &-question {
    background: $black;
  }
  .setting {
    &-content-header {
      background: $lightThemeBlocks;
      color: $black;
      position: relative;
      width: 90%;
      left: 5%;
      right: 5%;
      top: 2.5rem;
      border-radius: 5px;
      height: 150px;
      overflow: hidden;
      &_text_welcome-center {
        color: $black;
        position: relative;
        left: 200px;
        width: calc(100% - 200px);
        text-align: center;
        top: 4rem;
      }
      &_text_welcome-top {
        text-align: center;
        color: $black;
        margin: 0.75em 0;
      }
      // &::before{
      //   content: "";
      //   position: absolute;
      //   top: 6px;
      //   min-width: 200px;
      //   height: 100%;
      //   left: 0;
      //   background-repeat: no-repeat;
      //   background-size: cover;
      //   background-image: url("../../../../assets/images/Group.png");
      // }
    }
  }
  // полоска с названием раздела
  .personal-page {
    &-content-header {
      background: $lightThemeBlocks;
      color: $black;
      position: relative;
      width: 90%;
      left: 5%;
      right: 5%;
      top: 2.5rem;
      border-radius: 5px;
      height: 150px;
      overflow: hidden;
      &_text_welcome-center {
        color: $black;
        position: relative;
        left: 200px;
        width: calc(100% - 200px);
        text-align: center;
        top: 4rem;
      }
      &_text_welcome-top {
        text-align: center;
        color: $black;
        margin: 0.75em 0;
      }
      &::before {
        content: "";
        position: absolute;
        top: 6px;
        min-width: 200px;
        height: 100%;
        left: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("../../../../assets/images/ilustrations.png");
      }
      &_special-offer {
        width: 300px;
        margin: 0 auto;
        font-family: MontserratRegular;
        text-align: center;
        & p {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.2px;
          margin: 0;
        }
        & span {
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
        }
      }
    }
  }

  .personal_page {
    &-content {
      &-wrapper {
        position: relative;
        width: 100%;
        height: 90%;
        max-height: 90%;
        overflow: hidden;
      }
      &-heading {
        color: $black !important;
        position: relative;
        margin-top: 40px;
        font-weight: bold;
      }
      &-control_view_grid {
        position: relative;
        width: 100%;
        height: 30px;
        &-buttons {
          position: relative;
          left: 0px;
          display: inline-block;
          &-view_grid {
            background-size: 68%;

            background-image: url("../../../../assets/images/viewGrid.png");
            border: 1px solid rgba(37, 40, 43, 0.2);
            &.active {
              border: 1px solid $Government;
              box-sizing: border-box;
              border-radius: 2px;
            }
          }
          &-view_list {
            background-size: 68%;
            display: inline-block;
            background-image: url("../../../../assets/images/viewList.png");
            border: 1px solid rgba(37, 40, 43, 0.2);
            &.active {
              border: 1px solid $Government;
              box-sizing: border-box;
              border-radius: 2px;
            }
          }
        }
        &-sorts {
          position: absolute;
          right: 0px;
          height: 30px;
          width: 15%;
          left: 85%;
          display: inline-block;
          &-select_filters {
            min-width: 170px;
            left: -62px;
            position: relative;
            text-align: right;
            background: none;
            border: none;
            color: $black;
            &:focus {
              outline: none;
            }
            // p {
            //   position: absolute;
            //   text-align: right;
            // }
          }
          &-icon {
            background-image: url("../../../../assets/images/Vector.png");
          }
          &-icon_straight {
            border-left: 2px solid #52575c;
            opacity: 0.5;
            left: 98.5%;
            top: 15px;
          }
          &-icon_arrow {
            background-size: 90%;
            background-image: url("../../../../assets/images/arrow-up.png");
          }
        }
      }
      &-content_wrap_grid {
        &:hover {
          &::-webkit-scrollbar-button:vertical:start:decrement {
            background-color: rgba($color: #ebecf0, $alpha: 1);
            position: relative;
            width: 10px;
            background-size: contain;
            background-position-x: center;
            background-position-y: center;
            background-repeat: no-repeat;
            background-image: url("../../../../assets/images/arrowUp.png");
          }
          &::-webkit-scrollbar-button:vertical:end:increment {
            background-color: rgba($color: #ebecf0, $alpha: 1);
            position: relative;
            width: 10px;
            background-size: contain;
            background-position-x: center;
            background-position-y: center;
            background-repeat: no-repeat;
            background-image: url("../../../../assets/images/arrowDown.png");
          }
          &::-webkit-scrollbar {
            width: 10px !important;
          }
          /* Track */
          &::-webkit-scrollbar-track {
            background: #ebecf0 !important;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #cacccf !important;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555 !important;
          }
        }
        .folder {
          &-wrapper {
            &:hover {
              background: $lightThemeBlocks;
            }
            &.empty {
              overflow-y: auto;
              &::-webkit-scrollbar {
                width: 5px !important;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #f1f1f1 !important;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #d8d8d8 !important;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #555 !important;
              }
              width: 152px;
              height: 130px;
              background-position: 50% 17%;
              background-repeat: no-repeat;
              background-size: 47% 43%;
              display: inline-block;
              background-image: url("../../../../assets/images/folder-empty.png");
            }
            &.filled {
              &::-webkit-scrollbar {
                width: 5px !important;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #f1f1f1 !important;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #d8d8d8 !important;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #555 !important;
              }
              width: 152px;
              height: 130px;
              background-position: 50% 17%;
              background-repeat: no-repeat;
              background-size: 47% 43%;
              display: inline-block;
              background-image: url("../../../../assets/images/folder-full.png");
            }
          }
          &-star {
            position: absolute;
            width: 25px;
            height: 25px;
            background: #ffbc1f;
            border-radius: 30px;
            left: 95px;
            top: 50px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 70% 70%;
            display: inline-block;
            background-image: url("../../../../assets/images/star.png");
          }
          &-wrapper:hover .folder-name-textarea {
            background: $lightThemeBlocks;
            border: none;
            &::-webkit-scrollbar {
              width: 5px !important;
            }
            /* Track */
            &::-webkit-scrollbar-track {
              background: inherit !important;
            }
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: inherit !important; //#525252
            }
            /* Handle on hover */
            &::-webkit-scrollbar-thumb {
              background: inherit !important;
            }
          }
          &-name {
            &-textarea {
              color: $black;
              &:hover,
              &:focus {
                border: none;
              }
              &.active {
                &:hover,
                &:focus {
                  outline: none;
                }
              }
            }
          }
          &-date {
            color: #969899;
          }
          &-context_menu {
            background: rgba(37, 40, 43, 0.1);
            background-image: url("../../../../assets/images/menu_white.png");
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
      &-content_wrap_list {
        & tr,
        td,
        tbody,
        thead {
          color: black !important;
        }
        & tbody tr {
          border-top: 3px solid rgba(37, 40, 43, 0.1);
        }
        & .table-responsive {
          &::-webkit-scrollbar-button:vertical:start:decrement {
            background-color: rgba($color: #ebecf0, $alpha: 1);
          }
          &::-webkit-scrollbar-button:vertical:end:increment {
            background-color: rgba($color: #ebecf0, $alpha: 1);
          }
          &:hover {
            &::-webkit-scrollbar-track {
              border: none;
              background: #ebecf0 !important;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              border: none;
              background: #cacccf !important;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              border: none;
              background: #555 !important;
            }
          }
          &::-webkit-scrollbar-track {
            border: none;
            background: transparent !important;
          }
          /* Handle */
          &::-webkit-scrollbar-thumb {
            border: none;
            background: transparent !important;
          }
          &::-webkit-scrollbar {
            width: 10px;
            background: transparent !important;
          }
        }
      }
    }
  }
  .context_menu {
    &-wrap {
      background: #37393a !important;
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
      box-sizing: border-box;
      box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
    }
  }
  .context_menu-delete_button {
    color: #ff6262;
    &:hover {
      &::before {
        content: none;
      }
    }
  }

  .folder_list {
    &-name {
      &-textarea {
        border: none;
        color: $black;
        &::-webkit-scrollbar {
          width: 5px !important;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: $lightThemeBody !important;
        }
      }
    }
    &-context_menu {
      outline: none !important;
      &:hover {
        // background: rgba(255, 255, 255, 0.1);
        background-image: url("../../../../assets/images/menu_white.png");
        background-repeat: no-repeat;
        opacity: 1;
        background-size: contain;
      }
      &:focus {
        // background: rgba(255, 255, 255, 0.1);
        background-image: url("../../../../assets/images/menu_white.png");
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 1;
        outline: none;
        display: block;
        // .folder-wrapper {
        //   background: #16191d;
        //   border-radius: 4px;
        // }
      }
      height: 25px;
      right: 5%;
      top: 4%;
      background: none;
      border: none;
      box-sizing: border-box;
      // border-radius: 2px;
      opacity: 0.5;
      background-image: url("../../../../assets/images/menu_white.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  & .personal-page_setting_toggle {
    &-link {
      opacity: 0.5;
      color: $textForSidebarWhiteTheme;
      &:hover {
        color: $textForSidebarWhiteTheme;
      }
    }
  }
}
// toggle switcher( переключение темы)
.theme-light {
  &_toggler-switcher {
    display: block;
    margin: 5em;
    color: $black;
    & .toggle-control {
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 52px;
      border-radius: 20px;
      background: url(../../../../assets/images/sky-1365325_1920.png);
      background-size: cover;
      background-position: center;
      transition: 0.15s ease-in;
      border: none;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 24px;
        height: 24px;
        border-radius: 20px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          #e36307 0%,
          #e89806 100%
        );
        border: 1px solid #e89806;
        box-sizing: border-box;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        background-position: center;
        transition: 0.15s ease-in;
        border: none;
      }
    }
    .toggle-control__input:checked ~ .toggle-control {
      &:after {
        left: 52px - 24px;
      }
    }
  }
}

.light-header {
  background-color: $lightThemeHeader !important;
  & .header-navbar_brand-logo {
    background-image: url("../../../../assets/images/LogoWhiteTheme.png");
  }
  & .header-navigations-outin {
    position: absolute;
    right: 1%;
    top: 6px;
    background-image: url("../../../../assets/images/logout_white.png");
  }
  & a {
    color: black !important;
  }
}

.light {
  //
  // sidebar in pfml
  .col_pfml-sidebar {
    .pfml-sidebar-device_title {
      color: #25282b;
      background: $backGroungTabWhite;
    }
  }
  .vls-header-menu-classic ul li {
    &:hover {
      background: #d3d5dc;
    }
  }
  //sidebar in vls
  .vls-sidebar_tabs_wraper-tab.active {
    background: #fdfdff !important;
    color: #25282b !important;
    border-right: 1px solid #d3d5dc !important;
    border-bottom: 1px solid #fdfdff !important;
  }
  .nav-tabs {
    border-top: 1px solid #d3d5dc !important;
    border-right: 1px solid #d3d5dc !important;
    border-bottom: 1px solid #d3d5dc !important;
    background-color: $backGroungTabWhite;
  }
  .vls-sidebar-device_title {
    color: #25282b;
    background-color: $backGroungTabWhite;
  }
  .sidebar__options {
    border: none;
  }
  .tabs_custom {
    border: none;
  }
  .button__tab {
    background: $lightThemeBlocks;
    border-right: 1px solid #cacccf;
  }
  .tabs_button-group {
    background: $lightThemeBlocks;
    border: 1px solid #cacccf;
  }
  .wrapper-choose-setting {
    border: 1px solid #d3d5dc;
    box-sizing: border-box;
    border-radius: 2px;
    .choose-setting {
      border: none;
      border-right: 1px solid #d3d5dc;
      &:last-child {
        border-right: none;
      }
    }
  }
  .choose-setting__radio-button {
    & label {
      color: $lightThemeTextForSidebar;
    }
  }
  input[type="radio"] {
    background: $lightThemeBlocks;
    border: 1px solid rgba(37, 40, 43, 0.05);
    box-shadow: inset 3px 3px 10px rgba(0, 0, 0, 0.1),
      inset -3px -3px 10px rgba(75, 85, 116, 0.1);
    &:checked {
      border: 1px solid $Government;
    }
  }
  .button__option-menu .vls-table_data-oscilloscope-btn {
    color: #4a9eea !important;
  }
  .vls-sidebar_tabs_wraper-tab .nav-item .nav-link {
    color: rgba(82, 87, 92, 0.7) !important;
  }
  .vls-table_data-oscilloscope-title,
  .vls-tachometer-title,
  .vls-dc_machine-windings-title {
    color: #25282b;
  }
  .vls-header-menu-active ul {
    border-radius: 0px;
  }
  .toggle-switcher {
    border-top: 0px solid #787575;
    border-left: 0px solid #787575;
    border-right: 0px solid #787575;
  }
  .light .toggle-switcher {
    border: 0px solid #d3d5dc;
  }
  .vls-sidebar_tabs_wraper-tab .nav-item .nav-link {
    color: rgba(82, 87, 92, 0.7) !important;
  }
  .vls-sidebar_tabs_wraper-tab {
    color: rgba(82, 87, 92, 0.7) !important;
  }
  .vls-dc_machine-model_wrapper-block,
  .vls-dc_machine-windings {
    border: 1px solid #d3d5dc;
    &.border_none {
      border: none;
    }
  }
  .vls-sidebar_tabs_wraper-tab.active {
    border-top: 0px solid #353434 !important;
    border-left: 0px solid #353434 !important;
    border-right: 0px solid #353434 !important;
    // border-bottom: 1px solid #353434 !important;
  }
  .tabs-help_text,
  .vls-table_data-oscilloscope-btn-help_text,
  .default_components-help_text {
    color: rgba(82, 87, 92, 0.7);
  }
  .default_components-radio_btn {
    color: #25282b;
  }
  .table-vls-table_data-oscilloscope-table {
    border-top: 1px solid #d3d5dc;
    color: #25282b;
  }
  .table-vls-table_data-oscilloscope-table th,
  .table-vls-table_data-oscilloscope-table td {
    border: 1px solid #d3d5dc;
    color: #25282b;
  }
  .wrapper__input {
    & label {
      color: $darckTextForModal;
    }
    & .input__data {
      background: #f2f3f7;
      color: $darckTextForModal;
      border: 1px solid rgba(211, 213, 220, 0.3);
    }
    &-input {
      background: #f2f3f7;
      border: 1px solid rgba(211, 213, 220, 0.3);
      box-sizing: border-box;
      &-dec_btn {
        background: #f2f3f7;
        background-repeat: no-repeat;
        background-size: 70%;
        background-position: center;
        background-image: url("../../../../assets/images/arrowForInputNumberWhiteDown.png");
      }
      &-inc_btn {
        background: #f2f3f7;
        background-repeat: no-repeat;
        background-size: 70%;
        background-position: center;
        background-image: url("../../../../assets/images/arrowForInputNumberWhite.svg");
      }
    }
  }
  .checkbox {
    & label {
      &::before {
        background: $lightThemeBlocks;
        border: 1px solid #cacccf;
      }
    }
    .custom-checkbox:checked + label::before {
      background-color: $Government;
      border: 1px solid #3d88cc;
      border-radius: 2px;
      background-image: url(/static/media/arrowCheckBox.8d76847a.svg);
      background-size: 60%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .current {
    color: $darckTextForModal;
  }
  .toggle-switcher {
    color: $darckTextForModal;
    border: 1px solid $borderWhiteTheme;
    & .toggle-control {
      border: 1px solid rgba(37, 40, 43, 0.2);
      box-shadow: inset 3px 3px 10px rgba(75, 85, 116, 0.1),
        inset -3px -3px 10px rgba(75, 85, 116, 0.1);
      border-radius: 9px;
      background-color: $lightThemeBlocks;
    }
  }
  & .wrapper-current {
    width: 282px;
    & .toggle-switcher {
      width: 280px;
      border-bottom: none;
    }
  }
  & .wrapper-current-toggle {
    border: 1px solid $borderWhiteTheme !important;
    & h5,
    label {
      color: $lightThemeTextForSidebar;
    }
  }
  & .wrapper-table-for-chart {
    & .wrapper-table {
      border: 1px solid $borderWhiteTheme;
      & .col {
        border-right: 1px solid $borderWhiteTheme;
      }
      & .row {
        border-top: 1px solid $borderWhiteTheme;
      }
    }
  }
  .wrapper_table-info {
    & p {
      color: $black;
    }
    & .col {
      border-top: 1px solid $borderWhiteTheme;
      border-bottom: 1px solid $borderWhiteTheme;
    }
    & .col:nth-child(odd) {
      border-right: 1px solid $borderWhiteTheme;
      border-left: 1px solid $borderWhiteTheme;
    }
    & .row {
      border-top: 1px solid $borderWhiteTheme;
    }
  }
  .wrapper-setting-machine {
    & .toggle-switcher {
      border: none;
    }
  }

  & .vls-drop_down_wrap_option-wrap_confirm {
    margin-top: 20px;
    background: $w !important;
    position: unset;
  }

  .person-area_setting-option {
    color: $black;
  }
  & .personal_page-buttons {
    border-top: 2px solid #e8e8e8;
    border-right: 2px solid #e8e8e8;
  }
  & .personal_page-navigations {
    border-right: 2px solid #e8e8e8;
  }
  & .personal_page-navigations-nav {
    &-all_projects {
      &::before {
        background-position: center;
        background-size: 70%;
        background-image: url("../../../../assets/images/folder_white.png");
      }
    }
    &-my_projects {
      &::before {
        background-position: center;
        background-size: 70%;
        background-image: url("../../../../assets/images/folder-cloud_white.png");
      }
      &:hover {
        & .personal_page-navigations-nav-add_project {
          background: rgba(229, 242, 255, 0.7);
          color: $black;
        }
      }
    }
    &-add_project {
      background: rgba(229, 242, 255, 0.7);
      color: rgba(255, 255, 255, 0.5);
    }
    &-favorite {
      &::before {
        background-position: center;
        background-size: 70%;
        background-image: url("../../../../assets/images/circle-star_white.png");
      }
    }
    &-search {
      &::before {
        content: "";
        background-position: center;
        background-size: 70%;
        background-image: url("../../../../assets/images/search_white.png");
      }
    }
    &-trash {
      &::before {
        background-position: center;
        background-size: 70%;
        background-image: url("../../../../assets/images/trash-empty_white.png");
      }
    }
    &-settings {
      &::before {
        background-position: center;
        background-size: 70%;
        background-image: url("../../../../assets/images/settings_white.png");
      }
    }
    & .page-navigations-active {
      &::after {
        background: rgba(229, 242, 255, 0.5) !important;
      }
    }
    a {
      color: $black !important;
      &:hover {
        text-decoration: none;
        list-style: none;
        font-weight: bold;
        &::after {
          background-color: rgba(229, 242, 255, 0.5);
        }
      }
    }
  }
}
.context_menu-wrap {
  &.light-popover {
    .arrow {
      &::before {
        content: none !important;
        border-bottom-color: $lightThemeBody !important;
      }
      &::after {
        content: none !important;
      }
      &::after {
        content: "" !important;
        transform: rotate(45deg) !important;
        border: none !important;
        background: #fdfdff;
        // opacity: 0.8;
        height: 15px !important;
        width: 15px !important;
        border-radius: 6px;
        top: calc(-0.5rem - -10px) !important;
        z-index: 0 !important;
        border-left: 1px solid rgba(37, 40, 43, 0.05) !important;
        border-top: 1px solid rgba(37, 40, 43, 0.05) !important;
        box-sizing: border-box;
        box-shadow: none;
        border-radius: 4px;
      }
    }
  }

  .popover-body {
    z-index: 2 !important;
  }
}
.light-popover {
  background: $lightThemeBody !important;
  &.pfml-menu-burger-custom_translate {
    background-color: $lightThemeHeader !important;
  }
  &.pfml-menu-classic-custom_translate {
    background-color: $lightThemeHeader !important;
  }
  &.vls-menu-burger-custom_translate {
    background-color: $lightThemeHeader !important;
  }
  &.vls-menu-classic-custom_translate {
    background-color: $lightThemeHeader !important;
  }

  & .popover-body li a {
    color: black;
    &:hover {
      color: $w;
    }
  }
  & .popover-body li {
    color: black;
    &:hover {
      color: $w;
    }
  }
  & .arrow {
    &::after {
      // border-bottom-color: $lightThemeBody !important;
    }
    &::before {
      border-bottom-color: $black !important;
      opacity: 0.5;
    }
  }
}
.light-project-details {
  & .table-responsive {
    &::-webkit-scrollbar-button:vertical:start:decrement {
      background-color: rgba($color: #ebecf0, $alpha: 1);
    }
    &::-webkit-scrollbar-button:vertical:end:increment {
      background-color: rgba($color: #ebecf0, $alpha: 1);
    }
    &::-webkit-scrollbar {
      border: none;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      border: none;
      background: #ebecf0 !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border: none;
      background: #cacccf !important;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      border: none;
      background: #555 !important;
    }
  }
  & .project_details-content-heading {
    & a::before {
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../../../assets/images/backWhiteProject.svg");
    }
  }
  & .file_and_folders-context_menu {
    background-image: url("../../../../assets/images/menu_white.png");
    background-repeat: no-repeat;
    background-position: center;
  }
  & .file_and_folders-name-textarea.active:focus {
    border: 1px solid $black;
  }
  & .file_and_folders-name-textarea {
    color: $black;
  }
  & a {
    color: $black;
    &:hover {
      color: $black;
    }
  }
  & .default_components-button {
    background-image: url("../../../../assets/images/menu_white.png");
  }
  & .vls_wrap-label,
  .pfml_wrap-label {
    color: $black;
  }
  & .scrollbar-wrap_table {
    background: rgba(249, 249, 250, 0.5);
  }
  & tr,
  th,
  td {
    color: $black !important;
  }
  & .table-project_details.table-hover tbody tr:hover {
    border: none !important;
    background: rgba(229, 242, 255, 0.5) !important;
  }
  & .vls_wrap-content-button_wrap-text,
  .pfml_wrap-content-button_wrap-text {
    color: $black;
  }
}
.light-overlay_trigger {
  background-color: $lightThemeBody !important;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06) !important;
  & li {
    color: $black;
    & .active {
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        right: 0px;
        // top: 26px;
        height: 30px;
        background-image: url("../../../../assets/images/check_mark.png");
      }
    }
    &:hover {
      &::before {
        content: "";
        position: absolute;
        width: 1000px;
        height: 30px;
        left: -100px;
        background: $lightThemeHeader;
        z-index: -1;
      }
    }
  }
}

.light {
  .wrapper__input {
    & label {
      color: $textForSidebarWhiteTheme;
    }
  }
  & .vls-drop_down_wrap_option-header_text {
    color: $black;
  }
  & .vls-drop_down_wrap_option-wrap_scheme_rigidity {
    & label {
      color: $textForSidebarWhiteTheme;
    }
  }
  & .vls-drop_down_wrap_option-title_scheme_rigidity {
    color: #25282b;
  }
  & .content-border-line {
    background: #ebecf0;
  }
  & .sidebar-modal__drop-modal__all-data {
    color: $black;
  }
  .sidebar-modal__drop-modal::after {
    background: url("../../../../assets/images/arrowDownSidebarLight.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  & .sidebar__value {
    & p {
      color: $black;
    }
  }
  // page search
  & .personal_page-search-project {
    border-bottom: 1px solid rgba(161, 172, 189, 0.25);
    & input {
      color: $black;
    }
  }
  & .personal_page-done-search {
    color: $black;
  }
}
.light {
  .modal {
    &-wrap {
      top: 42% !important;
      left: -5% !important;
      &-backdrop {
        background: #001a34 !important;
        opacity: 0.7 !important;
      }
      .modal {
        &-content {
          width: 406px;
          height: 190px;
          background: #fdfdff;
          box-shadow: -12px -12px 24px rgba(35, 111, 153, 0.04),
            12px 12px 24px rgba(35, 111, 153, 0.04);
          border-radius: 4px;
          .modal-text_info_block {
            color: #25282b;
          }
          .modal-close_btn {
            position: absolute;
            opacity: 1;
            top: 12px;
            right: 12px;
            width: 20px;
            height: 20px;
            cursor: pointer;
            background-image: url(../../../../assets/images/closeModalWhite.svg);
          }
          .modal-load-text {
            color: #25282b;
          }
        }
      }
    }
  }
}
// pfml
.light {
  & .col_pfml-sidebar {
    background: $lightThemeBody;
  }
  .pfml-row_header {
    & .pfml-header-menu-classic {
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 20px;
        right: 0px;
        top: 6px;
        background: #d3d5dc;
      }
      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 20px;
        left: 23px;
        top: 7px;
        background: #d3d5dc;
      }
    }
    & .pfml-header-menu-active {
      background-color: $lightThemeHeader !important;
      & ul {
        background: $lightThemeHeader;
      }
    }
    background: $lightThemeHeader;
    & li {
      color: black;
    }
    & .pfml-header-project_info-text {
      color: #25282b;
    }
    & .pfml-header-project_info-project_name {
      color: #25282b;
      &::after {
        background-image: url("../../../../assets/images/arrowDown.png");
      }
    }
    & .pfml-header-logout {
      background-image: url("../../../../assets/images/logout_white.png");
    }
  }
  // toolpanel in pfml
  & .col_pfml-header-menu_left {
    background: $lightThemeBody;
    border-right: 2px solid $borderWhiteTheme;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  }
  & .pfml-drop-menu {
    background-image: url("../../../../assets/images/menu_light-theme.png");
  }
}
