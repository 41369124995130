.svg_animate {
  &-gear {
      $timeDelay: 5s;
    &-blue_big {
      transform-origin: center;
      animation: rotateGearPositiv $timeDelay infinite linear;
      transform-box: fill-box;
    }
    &-blue_migl {
      transform-origin: center;
      animation: rotateGearNegativ $timeDelay infinite linear;
      transform-box: fill-box;
    }
    &-blue_min {
      transform-origin: center;
      animation: rotateGearPositiv $timeDelay infinite linear;
      transform-box: fill-box;
    }
    &-white_big {
      transform-origin: center;
      animation: rotateGearPositiv $timeDelay infinite linear;
      transform-box: fill-box;
    }
    &-white_midl {
      transform-origin: center;
      animation: rotateGearNegativ $timeDelay infinite linear;
      transform-box: fill-box;
    }
    &-white_min {
      transform-origin: center;
      animation: rotateGearNegativ $timeDelay infinite linear;
      transform-box: fill-box;
    }
  }
}
@keyframes rotateGearNegativ {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes rotateGearPositiv {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// class="svg_animate-gear-blue_big"
