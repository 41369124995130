@font-face{
    font-family: "MontserratRegular";
    src: url("./MontserratRegular/MontserratRegular.eot");
    src: url("./MontserratRegular/MontserratRegular.eot?#iefix")format("embedded-opentype"),
    url("./MontserratRegular/MontserratRegular.woff") format("woff"),
    url("./MontserratRegular/MontserratRegular.ttf") format("truetype")

}
    
@font-face{
    font-family: "RobotoRegular";
    src: url("../fonts/RobotoRegular/RobotoRegular.eot");
    src: url("../fonts/RobotoRegular/RobotoRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/RobotoRegular/RobotoRegular.woff") format("woff"),
    url("../fonts/RobotoRegular/RobotoRegular.ttf") format("truetype")
}
    
