.modal_canvas{
    &-wrapper{
        position: absolute;
        width: calc(100% - 345px);
        height: 100%;
        z-index: 9999999999;
        background-color: rgba($color: #000000, $alpha: 1);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}