.details_news {
  background: linear-gradient(
      208.91deg,
      #fdfdff 16.72%,
      rgba(255, 255, 255, 0) 123.41%
    ),
    #ebecf0;
  .container {
    @include mob {
      max-width: 90%;
    }
  }
  &-back_btn {
    position: relative;
    height: 25px;
    margin-top: 50px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #25282b;
    padding-left: 25px;
    cursor: pointer;
    transition: .3s;
    &:hover {
      color: #a1acbd;
    }
    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0px;
      background-repeat: no-repeat;
      background-size: 45%;
      background-position: center;
      background-image: url("../../../../assets/images/backPage.png");
    }
  }
  &-article {
    position: relative;
    width: 100%;
    min-height: 200px;
    margin-bottom: 50px;
    margin-top: 20px;
    padding-top: 60px;
    padding-bottom: 30px;
    background: #fdfdff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(179, 189, 200, 0.1);
    border-radius: 16px;
    @include sm {
      padding-top: 30px;
    }
    &-wrap {
      padding: 0 60px;
      @include sm {
        padding: 0 20px;
      }
    }
    &-title {
      margin-bottom: 20px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 46px;
      letter-spacing: 0.2px;
      color: #25282b;
      @include ph {
        font-size: 2rem;
      }
    }
    &-under_title_text {
      display: block;
      margin-bottom: 10px;
      word-wrap: break-word;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      letter-spacing: 0.1px;
      color: #25282b;
      @include lg {
        font-size: 1.5rem;
      }
      @include ph {
        font-size: 1rem;
      }
    }
    &-under_title_date {
      display: block;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.1px;
      color: #a1acbd;
      @include xxs {
        margin-left: 20px;
        font-size: 13px;
      }
    }
    &-slider {
      position: relative;
      width: 100%;
      margin-top: 30px;
      overflow: hidden;
      margin-bottom: 30px;
      overflow: hidden;
      margin-bottom: 30px;
      @include sm {
        padding-bottom: 20px;
      }
      .container-landing {
        padding: 0 60px !important;
        @include sm {
          padding: 0 20px !important;
        }
      }

      &-wrap_slide {
        position: relative;
        width: 100%;
      }
      &-img {
        width: 100%;
      }
      &-text_under_img {
        display: block;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        text-align: right;
        letter-spacing: 0.1px;
        color: #52575c;
        text-align: right;
        @include xxs {
          font-size: 13px;
        }
      }
      &-arrow_slider {
        &-left,
        &-right {
          z-index: 3;
          position: absolute;
          top: 50%;
          width: 50px;
          height: 50px;
          background: #fdfdff;
          box-shadow: 4px 4px 16px rgba(179, 189, 200, 0.1),
            -4px -4px 16px rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          background-repeat: no-repeat;
          background-size: 85%;
          background-position: center;
          cursor: pointer;
          
        }
        &-left {
          left: 0;
          transform: translateX(50%) translateY(-50%);
          background-image: url("../../../../assets/images/ArrowForPaginationLeft.svg");
        }
        &-right {
          right: 0;
          transform: translateX(-50%) translateY(-50%);
          background-image: url("../../../../assets/images/ArrowForPaginationRight.svg");
        }
      }
    }
    &-content {
      position: relative;
      blockquote {
        position: relative;
        margin-top: 30px;
        padding-left: 50px;
        @include sm {
          padding-left: 30px;
        }
        &::before {
          content: "";
          position: absolute;
          background: #007eff;
          border-radius: 6px;
          width: 5px;
          height: 100%;
          left: 0;
        }
      }
      &-author {
        display: inline-block;
        padding-top: 20px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.1px;
        color: #25282b;
        border-top: 1px solid #cacccf;
        @include xxs {
          text-align: center;
          line-height: 23px;
        }
      }
    }
  }
  &-add_news {
    position: relative;
    width: 100%;
    height: auto;
    padding: 45px 0;
    background-color: #fdfdff;
    .container {
      padding-bottom: 90px;
      @include md {
        max-width: 100%;
      }
    }
    &-title {
      margin-bottom: 30px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      letter-spacing: 0.1px;
      color: #25282b;
      @include ph {
        font-size: 1.3rem;
      }
    }
    &-items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: stretch;
      grid-gap: 30px;
      @include md {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;

      }
      @include xs {
        grid-template-columns: 1fr;
      }
    }
  }
}
