.add_project {
    &-wrap {
        position: relative;
        display: block;
        top: -25px;
        &-title {
            font-family: $fontMonserat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.2px;
            color: $darckTextForModal;
            display: block;
            text-align: center;
        }
        &-form {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            &-input {
                font-family: $fontMonserat;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                letter-spacing: 0.2px;
                color: $darckTextForModal;
            }
            &-btn {
                position: relative;
                width: 133px;
                height: 40px;
                display: block;
                margin-top: 15px;
                margin-bottom: 20px;
                font-family: $fontMonserat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 1.25px;
                color: $w;
                background: #4a9eea;
                border-radius: 4px;
                border: none;
                &:focus {
                    outline: none;
                }
            }
        }
    }
}
