.vls {
  &-container {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    &.container {
      padding-right: 0px !important;
      padding-left: 0px !important;
      max-width: auto !important;
    }
  }
  &-row_header {
    justify-content: space-between;
    padding: 0 1em;
    align-items: center;
    height: 40px;
    background: $darckThemeHeader;
    .col-12 {
      padding: 0px 0px 0px 0px !important;
      height: 40px;
    }
  }
  &-row_canvas {
    height: calc(100% - 40px);
    overflow: hidden;
  }
  &-header {
    &-logo {
      position: relative;
      left: -5px;
      width: 23px;
      height: 39px;
      display: inline-block;
      background-image: url("../../../../assets/images/logoGreen.png");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }
    &-menu-classic {
      position: fixed;
      display: block;
      height: 40px;
      top: 0px;
      ul {
        padding-left: 29px;
        display: flex;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 20px;
          right: 0px;
          top: 6px;
          background: rgba(255, 255, 255, 0.1);
        }
        &::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 20px;
          // left: 23px;
          top: 7px;
          background: rgba(255, 255, 255, 0.1);
        }
        li {
          list-style: none;
          font-family: $fontRoboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 39px;
          letter-spacing: 0.2px;
          color: rgba(255, 255, 255, 0.8);
          padding-left: 10px;
          padding-right: 10px;
          opacity: 0.7;
          height: 40px;
          &:hover {
            background: #16191d;
          }
          &:focus {
            background: #16191d;
          }
          &::first-child {
            margin-right: 0px;
          }
          &::last-child {
            margin-right: 0px;
          }
        }
      }
    }
    &-menu-hidden {
      display: none;
    }
    &-menu-active {
      position: absolute;
      top: 40px;
      left: 5em;
      z-index: 1061 !important;
      width: 15em;
      height: 40px;
      display: block;
      height: auto;
      border-radius: 0px !important;
      background-color: $darckThemeHeader !important;
      ul {
        background-color: $darckThemeHeader;
        border-radius: 5px;
        padding-left: 10px !important;
        margin: 2px 0 0 0;
        li {
          list-style: none;
          font-size: 14px;
          line-height: 30px;
          cursor: pointer;
          text-align: start;
          margin-left: 13px;
          letter-spacing: 0.2px;
          color: rgba(255, 255, 255, 0.8);
          &::first-child {
            margin-right: 0px;
            &::before {
              border-radius: 5px;
            }
          }
          &::last-child {
            margin-right: 0px;
            &::before {
              border-radius: 5px;
            }
          }
          &::after {
            content: "";
            position: absolute;
            background-image: url("../../../../assets/images/arrowDownWhite.png");
            background-size: 40%;
            transform: rotate(-90deg);
            width: 30px;
            height: 30px;
            background-position: center;
            background-repeat: no-repeat;
            right: 1em;
          }
          &:hover::before {
            content: "";
            background: $BlueEmpty;
            width: 100%;
            left: 0;
            height: 30px;
            opacity: 0.5;
            position: absolute;
          }
        }
      }
    }
    &-project_info {
      height: 40px;
      position: relative;
      line-height: 30px;
      left: 0;
      display: inline-block;
      @include xxl {
        left: -10%;
      }
      @include xl {
        left: -11%;
      }
      @include nb {
        left: -14%;
      }
      @include lg {
        left: -10%;
      }
      &-text {
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.2px;
        color: rgba(255, 255, 255, 0.5);
      }
      &-project_name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #ffffff;
        cursor: pointer;
        &::after {
          content: "";
          width: 10px;
          height: 10px;
          top: 11px;
          margin-left: 5px;
          position: absolute;
          background-image: url("../../../../assets/images/arrowDownWhite.png");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
        }
      }
    }
    &-profile {
      width: auto;
      height: 40px;
      display: flex;
      position: fixed;
      right: 300px;
      @include lg {
        right: 140px;
      }
      &-link_avatar {
        position: relative;
        background: $DefaultGreen;
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 25px;
        border-radius: 30px;
        cursor: pointer;
        font-size: 20px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.2px;
        padding-left: 5%;
        color: #ffffff;
        /* text-align: center !important; */
      }
      &-will_share {
        &-button {
          height: 30px;
          width: 106px;
          margin-top: 5px;
          margin-bottom: 5px;
          background: #4a9eea;
          border-radius: 4px;
          color: #fff;
          border: none;
          &:focus {
            outline: none;
          }
        }
      }
    }
    &-logout {
      width: 22px;
      height: 22px;
      margin-top: 7px;
      margin-bottom: 14px;
      border: none;
      background: none;
      background-image: url("../../../../assets/images/logout.png");
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
}
.col_vls {
  position: relative;
  height: 100%;
  &-header-menu_left {
    width: 45px;
    background: #535353;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  }
  &-canvas {
    width: calc(100% - 345px);
  }
  &-sidebar {
    overflow: hidden;
    width: 300px;
    height: calc(100% - 40px);
    right: 0px;
    position: absolute;
    background: #535353;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  }
}
.vls {
  &-zoom_block {
    position: absolute;
    width: 30px;
    height: 60px;
    background: #f2f3f7;
    box-shadow: -3px -3px 6px #ffffff, 3px 3px 6px #d4d4d8;
    border-radius: 4px;
    bottom: 50px;
    right: calc(30px + 345px);
    &-icon_plus {
      position: relative;
      width: 100%;
      height: 30px;
      border: none;
      background: #f2f3f7;
      border-radius: 4px;
      background-image: url("../../../../assets/images/plus.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
    &-icon_minus {
      position: relative;
      width: 100%;
      height: 30px;
      border: none;
      background: #f2f3f7;
      border-radius: 4px;
      background-image: url("../../../../assets/images/minus.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
    &-line {
      position: relative;
      height: 1px;
      width: 100%;
      background: #fff;
      margin-top: -5px;
    }
  }

  &-drop_down_wrap_option {
    // &.arrow {
    //   background: #535353 !important;
    // }
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    min-width: 325px;
    height: 430px;
    &-header_text {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      display: inline-block;
      align-items: center;
      letter-spacing: 0.2px;
      color: #ffffff;
    }
    &-closed {
      position: absolute;
      margin: 0px 0px 0px 20px;
      cursor: pointer;
      top: 2%;
      right: 5%;
      width: 20px;
      height: 20px;
      display: inline-block;
      opacity: 0.5;
      background-image: url("../../../../assets/images/cansel.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      color: #fff;
    }
    &-label_control_input {
      position: relative;
      width: auto;
      height: 20px;
      font-size: 14px !important;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2px;
      opacity: 1;
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      color: #e8e8e8;
      &-td {
        margin-left: 11px;
      }
    }
    &-group_control_c_dt {
      position: relative;
      top: 15px;
      display: flex;
      margin-bottom: -5px !important;
    }
    &-control_input {
      position: relative;
      width: 80px !important;
      height: 20px !important;
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      letter-spacing: 0.2px;
      margin-left: 10px;
      background: #424242 !important;
      border: 1px solid rgba(255, 255, 255, 0.2) !important;
      box-sizing: border-box;
      border-radius: 1px !important;
      color: #e8e8e8 !important;
    }
    &-wrap_scheme_rigidity {
      margin-top: 15px;
      position: relative;
    }
    &-wrap_rigidity_control {
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      border-radius: 2px;
      position: relative;
      height: 40px;
      margin-top: 5px;
      display: flex;
      // justify-content: center;
      span {
        color: #e8e8e8 !important;
        margin: 10px 20px 10px 20px;
      }
      .rigidity_control {
        margin-top: 10px !important;
      }
    }
    &-wrap_rigidity_control_value {
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      border-radius: 2px;
      position: relative;
      height: 80px;
      margin-top: 5px;
      display: flex;
      // justify-content: center;
      span {
        color: #e8e8e8 !important;
        margin: 10px 20px 10px 20px;
      }
      .rigidity_control {
        margin-top: 10px !important;
      }
    }
    &-label_control_input_value {
      position: relative;
      width: auto;
      height: 15px;
      font-size: 14px !important;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2px;
      opacity: 1;
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      color: #e8e8e8;
    }
    &-form_group_input_value {
      top: 6px;
      left: -10px;
    }
    &-title_scheme_rigidity {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      letter-spacing: 0.2px;
      color: #e8e8e8;
    }
    &-control_mode {
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2px;
      color: #e8e8e8;
      margin-top: 20px;
    }
    &-wrap_confirm {
      margin: 0 auto;
      & .confirm_component-wrap {
        justify-content: center;
      }
    }
  }
}
.vls-menu-burger-custom_translate {
  border-radius: 0px !important;
  background-color: $darckThemeHeader !important;
  z-index: 1070;
}
.vls-menu-classic-custom_translate {
  border-radius: 0px !important;
  background-color: $darckThemeHeader !important;
  z-index: 1070;
}
.vls-drop-menu-vls {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 5em;
  background-image: url("../../../../assets/images/open-menu-drop.svg");
}
.vls-menu-active {
  width: 100%;
  position: absolute;
  left: 0;
  top: 40px;
  left: 0% !important;
  transform: translate(0px, 0px) !important;
  z-index: 1060 !important;
  display: block;
  max-width: 100% !important;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden;
  background: transparent;
  opacity: 0.7;
}
.vls-menu-hidden {
  display: none;
}
.vls {
  &-checkbox_data-oscilloscope {
    position: relative;
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    top: -60px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #e8e8e8;
    .custom-checkbox + label {
      width: 277px;
    }
  }
  &-table_data-oscilloscope {
    top: -11px;
    position: relative;
    &-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #e8e8e8;
    }
    .default_components-radio_btn {
      margin-top: 2px;
    }
    &-btn {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 12px;
      letter-spacing: 1.25px;
      position: relative;
      display: inline-block;
      width: 170px;
      height: 24px;
      top: -100px;
      left: 55px;
      &-help_text {
        position: relative;
        width: 228px;
        height: 40px;
        left: 40px;
        top: -85px;
        display: inline-block;
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: rgba(232, 232, 232, 0.3);
      }
    }
    &-line_end_out {
      position: relative;
      top: -80px;
    }
    &-line_out {
      position: relative;
      top: -35px;
    }
    .table-responsive {
      overflow: hidden;
      top: -15px;
      position: relative;
    }
    .tabs_custom {
      border: none !important;
      border-top: 0px solid #787575 !important;
      border-left: 0px solid #787575 !important;
      border-right: 0px solid #787575 !important;
    }
    &-label_for_radio_btn {
      position: relative;
      top: -6px;
      left: 30px;
      width: 67px;
      input[type="radio"] {
        position: absolute;
        left: -26px;
      }
    }
    &-input {
      width: 100%;
      height: 30px;
      border: none;
      position: relative;
      &:hover {
        border: 1px solid #4a9eea;
      }
    }
    &-input_wrapper {
      margin: 0px;
    }
  }
  &-voltage_source {
    &-input_number {
      top: -10px;
      left: 10px;
      position: relative;
      .wrapper__input-wrapper_add_btn {
        left: -100px;
      }
    }
    &-end_line {
      position: relative;
      top: -15px;
    }
  }
  &-rheostat {
    &-input_number {
      top: -10px;
      left: 10px;
      position: relative;
      .wrapper__input-wrapper_add_btn {
        left: -100px;
      }
    }
    &-end_line {
      position: relative;
      top: -15px;
    }
  }
  &-inductance {
    &-table_wrapper {
      position: relative;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  &-input_data {
    &-automat {
      position: relative;
      margin-right: 10px;
      margin-left: 10px;
      .wrapper__input {
        margin-top: 10px;
        &-wrapper_add_btn {
          left: -90px;
        }
      }
      input[type="number"] {
        width: 41px;
        height: 20px;
        position: relative;
      }

      display: contents;
    }
    &-terminals {
      position: relative;
      margin-right: 10px;
      margin-left: 10px;
      .wrapper__input {
        margin-top: 10px;
        margin-left: 10px;
        &-wrapper_add_btn {
          left: -100px;
        }
      }
      input[type="number"] {
        width: 41px;
        height: 20px;
        position: relative;
      }

      display: contents;
    }
  }
  &-checkbox_data {
    &-inductance {
      position: relative;
      margin-right: 10px;
      margin-left: 10px;
      .default_components-radio_btn {
        input[type="radio"],
        input[type="checkbox"] {
          position: absolute;
        }
        display: contents;
      }
    }
  }
  &-tachometer {
    &-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #e8e8e8;
      margin-left: 10px;
    }
    .default_components-radio_btn {
      width: 260px;
      .choose-setting__radio-button-label {
        width: 260px;
        line-height: 20px;
      }
    }
  }
  &-btn {
    &-rebuild {
      &-inductance {
        left: 75px;
        position: relative;
        display: inline-block;
        width: 140px;
        height: 24px;
        line-height: 10px !important;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
        color: #ffffff;
      }
    }
  }
  &-dc_machine {
    &-params_settings {
      .toggle-switcher {
        margin-top: 10px;
        border-top: 1px solid #787575;
        border-left: 1px solid #787575;
        border-right: 1px solid #787575;
        width: 275px;
        top: 15px;
        position: relative;
        border-radius: 2px 2px 0px 0px;
      }
    }
    &-model_wrapper {
      &-block {
        border: 1px solid #787575;
        box-sizing: border-box;
        border-radius: 2px;
        margin-right: 10px;
        margin-left: 10px;
        display: flex;
        .default_components-radio_btn {
          width: 150px;
          margin: 5px;
          top: -4px;
          position: relative;
          input[type="radio"] {
            top: 5px;
            margin-right: 5px;
          }
        }
      }
    }
    &-windings {
      border: 1px solid #787575;
      box-sizing: border-box;
      border-radius: 2px;
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 10px;
      &.border_none {
        border: none;
      }
      &-title {
        margin: 10px 10px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.2px;
        position: relative;
        color: #e8e8e8;
        display: block;
      }
      .vls-table_data-oscilloscope-input {
        height: 30px;
      }
      .wrapper-check-box {
        margin: 5px;
      }
      .default_components-radio_btn {
        // top: -27px;
        // position: relative;
      }
    }
  }
}
