.paginations_default {
  .arrow_btn {
    &:hover {
      text-decoration: none;
    }
    background: #f2f3f7;
    position: relative;
    width: 40px;
    display: inline-block;
    height: 40px;
    box-shadow: 4px 4px 16px rgba(179, 189, 200, 0.1),
      -4px -4px 16px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    text-align: center;
    line-height: 37px;
    margin-left: 10px;
    top: 15px;

    &.left {
      background-repeat: no-repeat;
      background-size: 85%;
      background-position: center;
      background-image: url("../../../../assets/images/ArrowForPaginationLeft.svg");
    }
    &.right {
      background-repeat: no-repeat;
      background-size: 85%;
      background-position: center;
      background-image: url("../../../../assets/images/ArrowForPaginationRight.svg");
    }
  }

  .page-item {
    position: relative;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    width: 40px;
    height: 40px;
    background: #fdfdff;
    box-shadow: 4px 4px 16px rgba(179, 189, 200, 0.1),
      -4px -4px 16px rgba(255, 255, 255, 0.1);
    border-radius: 4px !important;
    margin-left: 10px;
    &:hover {
      text-decoration: none;
    }
    .active {
      background: #007eff;
      border: 1px solid #007eff;
      box-sizing: border-box;
      border-radius: 4px;
    }
    border: none !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #52575c;
    border-radius: 4px;
  }
}
