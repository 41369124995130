.custom_menu {
  // &-wrap[x-placement = "top"] {
  //   .arrow {
  //     top: auto !important;
  //     &::after {
  //       top: auto !important;
  //     }
  //   }
  // }
  &-wrap_visible {
    position: absolute !important;
    height: 30px;
    width: fit-content;
    background: #212529;
    z-index: 999999999999999;
    border-radius: 5px;
    display: flex;
    flex-flow: row;
    // overflow: hidden;
    position: relative;
    button {
      border: none !important;
      &:focus {
        outline: none;
        outline: none;
      }
    }
  }
  &-plus {
    position: relative;
    // top: -9px;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    // border-radius: 5px 0px 0px 5px;
    background-color: #212529;
    color: #8f9193;
  }
  &-color_picker {
    position: relative;
    width: 55px;
    height: 30px;
    background-color: #212529;
  }
  &-delete {
    position: relative;
    width: 30px;
    height: 30px;
    display: block;
    background-color: #212529;
    background-size: 64%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../assets/images/trash-empty.png");
  }
  &-wrap_close {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 2;
    top: 0px;
    left: 0px;
    background: transparent;
  }
  &-api {
    position: relative;
    width: 30px;
    height: 30px;
    background-color: #212529;
    color: #8f9193;
  }
  &-question {
    position: relative;
    width: 30px;
    height: 30px;
    background-color: #212529;
    color: #8f9193;
  }
  &-close {
    position: relative;
    // top: -9px;
    width: 30px;
    height: 30px;
    background-color: #212529;
    color: #8f9193;
    margin-right: 5px;
    // border-radius: 0px 5px 5px 0px;
  }
  &-connector_manager {
    position: relative;
    width: 32px;
    height: 28px;
    display: inline-block;
    margin-left: 5px;
    opacity: 0.5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    background-image: url("../../../../assets/images/synchronize.svg");
    &-wrapper {
      max-height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 5px !important;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #555 !important;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #555 !important;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
      }
    }
    &-block_conection_element {
      position: relative;
      width: 245px;
      padding: 5px;
    }
    &-btn {
      &-connected {
        position: relative;
        width: 85%;
        border: none;
        margin-left: 10%;
        color: #fff;
        background-color: #ff8383;
        border-radius: 3px;
      }
      &-disconnected {
        position: relative;
        width: 85%;
        border: none;
        margin-left: 10%;
        color: #fff;
        background-color: #b9b9b9;
        border-radius: 3px;
      }
    }
  }
  &-wrap_btn {
    display: inline-block;
  }
  &-wrap_visible_false {
    display: none;
  }
}
.context_menu-fadeout {
  .arrow {
    display: none !important;
  }
  opacity: 0;
  position: absolute !important;
  top: 40px !important;
  left: 0% !important;
  transform: translate(0px, 0px) !important;
  z-index: 1060 !important;
  display: block;
  max-width: 100% !important;
  width: 100%;
  height: calc(100% - 40px);
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.3rem !important;
}

.context_menu-wrap {
  .arrow {
    &::before {
      content: none !important;
      border-bottom-color: #2e343a !important;
    }
    &::after {
      content: none !important;
    }
    &::after {
      content: "" !important;
      transform: rotate(45deg);
      border: none !important;
      background: #2e343a;
      height: 15px !important;
      width: 15px !important;
      border-radius: 6px;
      // top: calc(-0.5rem - -10px) !important;
      z-index: 0 !important;
      border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
      border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
      box-sizing: border-box;
      box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
    }
  }

  .popover-body {
    z-index: 2 !important;
  }
}
.context_menu-wrap[x-placement = "top"] {
  .arrow {
    &::after {
      transform: rotate(-137deg);
    }
  }
}

.context_menu {
  &-wrap {
    &.width_auto {
      max-width: 500px;
    }
  }
  &-pfml {
    &-header {
      &-check_box {
        position: relative;
        display: block;
        .custom-checkbox {
          position: absolute;
        }
      }
    }
  }
}
