.tool_panel {
  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &-controler {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 30px;
    margin-top: 10px;
    &-line {
      position: relative;
      background: rgba(255, 255, 255, 0.1);
      width: 100%;
      height: 2px;
    }
    &-on_and_off_btn {
      position: relative;
      width: 30px;
      height: 52px;
      margin: 10px 3px 8px 7px;
      background: #535353;
      box-shadow: -2px -2px 4px #5f5f5f, 2px 2px 4px #474747;
      border-radius: 2px;
      &.on {
        background-image: url("../../../../assets/images/on.svg");
        background-repeat: no-repeat;
        background-size: 83%;
        background-position: center;
      }
      &.off {
        background-image: url("../../../../assets/images/off.svg");
        background-repeat: no-repeat;
        background-size: 83%;
        background-position: center;
      }
    }
    &-pause_btn {
      position: relative;
      width: 30px;
      height: 30px;
      margin: 10px 3px 8px 7px;
      background: #535353;
      box-shadow: -2px -2px 4px #5f5f5f, 2px 2px 4px #474747;
      border-radius: 2px;
      &.pause {
        background-image: url("../../../../assets/images/paused.svg");
        background-repeat: no-repeat;
        background-size: 40%;
        background-position: center;
      }
      &.play {
        background-image: url("../../../../assets/images/play.svg");
        background-repeat: no-repeat;
        background-size: 40%;
        background-position: center;
      }
    }
  }
  &-elements {
    position: relative;
    width: 100%;
    height: 35px;
    margin-top: 5px;
    &-btn {
      position: relative;
      width: 100%;
      height: auto;
      border: none;
      background: none;
      padding: 0px 0px !important;
      &.active {
        &::before {
          content: "";
          position: absolute;
          width: 35px;
          height: 35px;
          margin: 3px 4px 5px 5px;
          background: #858585;
          border: 1px solid rgba(255, 255, 255, 0.03);
          box-sizing: border-box;
          box-shadow: -4px -4px 8px #5b5b5b, 4px 4px 8px #4b4b4b;
          border-radius: 2px;
        }
      }
      &:focus {
        outline: none;
        border: none;
        &::before {
          content: "";
          position: absolute;
          width: 35px;
          height: 35px;
          margin: 3px 4px 5px 5px;
          background: #5b5b5b;
          border: 1px solid rgba(255, 255, 255, 0.03);
          box-sizing: border-box;
          box-shadow: -4px -4px 8px #5b5b5b, 4px 4px 8px #4b4b4b;
          border-radius: 2px;
        }
      }
    }
    &:hover {
      &::before {
        content: "";
        position: absolute;
        width: 35px;
        height: 35px;
        margin: 3px 4px 5px 5px;
        background: #5b5b5b;
        border: 1px solid rgba(255, 255, 255, 0.03);
        box-sizing: border-box;
        box-shadow: -4px -4px 8px #5b5b5b, 4px 4px 8px #4b4b4b;
        border-radius: 2px;
      }
    }
    &-icon {
      position: relative;
      width: 30px;
      height: 30px;
      padding: 3px 3px;
      margin: 5px 8px 4px 7px;
      opacity: 0.7;
      &.min {
        padding: 6px 6px;
      }
    }
  }
  &-add_element_icon {
    position: relative;
    width: 4px;
    height: 4px;
    display: inline-block;
    top: -20px;
    left: 70%;
    z-index: 10;
  }
  &-add_element_select {
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    height: max-content;
    top: -60px;
    margin-left: 45px;
    background: #37393a;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #ffffff;
    border-left: 1px solid $BlueEmpty;
    &-text {
      position: relative;
      white-space: nowrap;
      height: 30px;
      line-height: 20px;
      padding: 5px 5px;
      cursor: pointer;
      &:hover {
        color: $BlueEmpty;
      }
    }
    &-key_code {
      position: relative;
      right: 0px;
      margin-left: 10px;
    }
    &.active {
      display: block;
    }
    &.deactive {
      display: none;
    }
  }
}
