.modal_gltf_loader {
  &-wraper {
    position: absolute;
    width: 300px;
    height: 200px;
    display: block;
    left: calc(50% - 150px);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 1);
    padding: 20px;
  }
  &-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #2e343a;
    position: relative;
  }
  &-spiner {
    height: 120px;
  }
  &-progress {
    text-align: center;
  }
}
