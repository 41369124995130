.auth_and_reg-left_column {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.auth_and_reg {
  position: absolute;
  width: 100%;
  height: 100%;
  &-left_column {
    position: relative;
    width: 100%;
    height: 100%;
    @include sm {
      height: 120%;
    }
    @include xxs {
      height: 100%;
    }
    &-col_l {
      @include lg {
        padding: 0 !important;
      }
      @include sm {
        position: absolute !important;
        width: 100% !important;
        height: calc(100% + 100%) !important;
        max-width: 100% !important;
      }
      @include mob {
        height: calc(100% + 75%) !important;
      }
      @include xxxs {
        height: calc(100% + 70%) !important;
      }
    }
    background-color: #f9f9fa;
    &-logo {
      position: relative;
      height: 40px;
      top: 60px;
      margin-left: 70px;
      cursor: pointer;

      @include md {
        margin-left: 60px;
      }
      &::before {
        content: "";
        width: 40px;
        height: 50px;
        top: -1px;
        left: -40px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 35px 50px;
        background-image: url("../../../../assets/images/logoGreen.png");
      }

      &-text_logo {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.1px;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        color: #25282b;
      }
    }
    &-text_under_logo {
      position: relative;
      height: 155px !important;
      width: 340px !important;
      top: 10%;
      margin-left: 30px;
      margin-right: 60px;
      font-size: 16px;
      letter-spacing: 0.2px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 124.5%;
      letter-spacing: 0.2px;
      color: #cccccc;
      p {
        margin-bottom: 0px;
      }
      @include xxl {
        top: 80px;
      }
      @include xl {
        font-size: 18px;
        width: 288px !important;
      }
      @include vg {
        font-size: 16px;
        width: 200px !important;
        top: 90px;
      }
      @include md {
        margin-left: 15px;
        font-size: 14px;
      }
    }
    &-bottom_animate_img {
      position: absolute;
      width: 100%;
      height: 50%;
      top: 50%;
      @include xxs {
        height: 22%;
        top: 78%;
      }
      @include mob {
        top: 50%;
      }
      @include xxxs {
        top: 78%;
      }
    }
  }
  &-right_column {
    //arrowRightBlue.png
    &-col_r {
      @include sm {
        position: absolute !important;
        width: 90% !important;
        height: 100% !important;
        max-width: 100% !important;
        top: 35% !important;
        max-width: 100% !important;
        display: block !important;
        left: 5% !important;
      }
    }
    position: relative;
    width: 100%;
    height: 100%;
    &-back_btn {
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: #4a9eea;
      display: inline-block;
      width: 200px;
      position: absolute;
      top: 9%;
      left: 11%;

      @include md {
        left: 10%;
      }
      @include sm {
        left: 25%;
        top: 2%;
        z-index: 20;
      }
      @include mob {
        left: 5%;
      }
      @include xxs {
        z-index: 99999;
        left: 15%;
        top: 2%;
        span {
          display: none;
        }
      }
      &::before {
        content: "";
        width: 40px;
        height: 40px;
        top: 2px;
        left: -28px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-image: url("../../../../assets/images/arrowRightBlue.png");
      }
    }
    &-form {
      position: absolute;
      left: 40%;
      width: 400px;
      top: 30%;
      &.registrations_custom_top {
        top: 15%;
        left: 40%;
      }
      @include xxl {
        left: 33% !important;
      }
      @include vg {
        top: 3%;
        &.authorizations_custom_margin_top {
          top: 20%;
          left: 22%;
        }
        &.forgot_password_custom_margin_top {
          top: 20%;
          left: 22%;
        }
      }
      @include sm {
        left: 22% !important;
        top: 0% !important;
      }
      @include mob {
        left: 12% !important;
      }
      @include xxs {
        width: 320px !important;
        left: 0% !important;
      }
      @include xxxs {
        width: 285px !important;
      }
      &-forgot_password_bottom_text {
        position: relative;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1px;
        color: #52575c;
        margin-top: 25px;
        span {
          text-align: center;
        }
      }
      &-help_text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        padding-left: 10px;
        align-items: center;
        letter-spacing: 0.1px;
        color: #ff6262;
        &.active {
          &::before {
            content: "";
            border-radius: 30px;
            position: absolute;
            width: 4px;
            height: 4px;
            left: 2px;
            margin-top: 10px;
            background: #ff6262;
            transform: matrix(-1, 0, 0, 1, 0, 0);
          }
        }
      }
      &-heder {
        position: relative;
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 54px;
        text-align: center;
        color: #25282b;
        @include vg {
          font-size: 27px;
        }
      }
      &-label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #25282b;
      }
      &-input_style {
        position: relative;
        color: #495057 !important;
        background: #f3f3f4 !important;
        border: 2px solid rgba(37, 40, 43, 0.02) !important;
        box-sizing: border-box;
        border-radius: 4px !important;
        &:hover {
          color: #495057 !important;
          background-color: #fff !important;
          border-color: #80bdff !important;
          outline: 0 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
        }
        &.password_autn_add {
          @include vg {
            margin-top: -25px;
          }
        }
      }
      &-sign_in_btn {
        position: relative;
        width: 100%;
        height: 45px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.25px;
        border-color: #4a9eea !important;
        background-color: #4a9eea !important;
        border-radius: 4px;
        color: #ffffff;
        font-family: Montserrat;
      }
      &-group_password {
        height: 40px;
      }
      &-forgot_password {
        position: absolute;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #4a9eea;
        right: 0;

        &:hover {
          color: #007eff;
          text-decoration: none;
        }
        @include vg {
          position: relative;
          display: inline-block;
          right: -24%;
        }
        @include xxs {
          right: -5%;
        }
      }
      &-btn_view_pass {
        position: absolute;
        background: none !important;
        border: none !important;
        width: 5px;
        height: 15px;
        top: 192px;
        right: 3%;
        z-index: 2 !important;
        @include vg {
          position: relative;
          top: 5px;
          right: -93%;
        }
        @include xxs {
          right: -90%;
        }
        &.active {
          background-repeat: no-repeat !important;
          background-size: 82% !important;
          background-image: url("../../../../assets/images/eyeVisible.png") !important;
        }
        &.deactive {
          background-repeat: no-repeat !important;
          background-size: 82% !important;
          background-image: url("../../../../assets/images/eyeHidden.png") !important;
        }
        &:focus {
          box-shadow: none !important;
          outline: none !important;
        }
      }
      &-btn_view_pass_registration {
        position: relative;
        background: none !important;
        border: none !important;
        width: 5px;
        height: 15px;
        top: 30px;
        left: 90%;
        z-index: 2;
        @include vg {
          position: relative;
          top: 30px;
          right: -90%;
        }
        &.active {
          background-repeat: no-repeat !important;
          background-size: 82% !important;
          background-image: url("../../../../assets/images/eyeVisible.png") !important;
        }
        &.deactive {
          background-repeat: no-repeat !important;
          background-size: 82% !important;
          background-image: url("../../../../assets/images/eyeHidden.png") !important;
        }
        &:focus {
          box-shadow: none !important;
          outline: none !important;
        }
      }
      &-terms_use {
        position: relative;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1px;
        color: #52575c;
        margin-top: 25px;
      }
      &-terms_use_link {
        position: relative;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #4a9eea;
        right: 0;
        a {
          color: #4a9eea;
          &:hover {
            color: #007eff;
            text-decoration: none;
          }
        }
        &-last_el {
          margin-bottom: 0.5rem;
        }
      }
      &-line {
        position: relative;
        width: 50%;
        left: 25%;
        background: #cacccf;
        height: 1px;
        display: block;
      }
      &-registrations_link {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #4a9eea;
        &:hover {
          color: #007eff;
          text-decoration: none;
        }
      }
    }
  }
}
