.tooltip_vls {
  &-wrap_visible {
    position: absolute;
    display: block;
    width: 208px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    z-index: 99999999999999999999;
    &::before {
      content: "";
      position: absolute;
      width: 25px;
      height: 25px;
      top: 15px;
      left: -3px;
      transform: rotateZ(135deg);
      background-color: #fff;
      // background-size: cover;
      // background-repeat: no-repeat;
      // background-image: url("../../../../assets/images/arrowForTolltip.png");
    }
  }
  &-title {
    position: relative;
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #4a9eea;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    word-wrap: break-word;
  }
  &-content {
    position: relative;
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.2px;
    color: rgba(37, 40, 43, 0.6);
    padding: 0px 8px 8px 8px;
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;
    border-radius: 4px;
    &::-webkit-scrollbar {
      width: 5px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #ffffff !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #d8d8d8 !important;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555 !important;
    }
  }
  &-wrap_visible_false {
    display: none;
  }
  &-wrap_close {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: transparent;
  }
}
.vls-tooltip_sidebar {
  position: absolute;
  width: 180px;
  height: 130px;
  background-color: $blueTooltip;
  border-radius: 8px;
  z-index: 90;
  display: flex;
  justify-content: center;
  align-items: center;
  &-messenge {
    position: relative;
    padding: 10px;
    font-family: $fontMonserat !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $textColorForTooltip;
    top: calc(50% - 112px/2 + 1px);
  }
  &-arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    // background: red;
    border-radius: 3px;
    transform: rotateZ(45deg)translate(-34px, -39px);
    transform-origin: center;
    background-color: $blueTooltip;
  }
}
