.error_page {
  &-not_found {
    background-image: url("../../../../assets/images/notFoundPage.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    width: 100%;
    left: 0;
    height: 1000px;
    left: 0px;
    top: 105px;
    background-color: linear-gradient(
        186.26deg,
        #fdfdff 16.26%,
        rgba(253, 253, 255, 0) 91.31%
      ),
      #ebecf0;
    display: flex;
    justify-content: center;
    @include lg {
      height: 100vh;
      min-height: 390px;
    }
    &-info {
      position: relative;
      width: 500px;
      height: 400px;
      margin-top: 145px;
      text-align: center;
      @include sm {
        margin-top: 0px !important;
      }
      @include lg {
        height: 270px;
      }
      @include xxs {
        top: -25px;
      }
      &-green_text {
        position: relative;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 112px;
        line-height: 64px;
        letter-spacing: 0.2px;
        color: #40dd7f;
      }
      &-under_text {
        position: relative;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 160%;
        text-align: center;
        letter-spacing: 0.1px;
        color: #25282b;
        margin-top: 2rem;
        @include lg {
          width: 180px;
          left: 32%;
          right: 30%;
        }
        @include xxs {
          left: 25%;
        }
      }
      &-link_to_home {
        position: relative;
        width: 179px;
        height: 55px;
        background: #25282b;
        border: 1px solid #25282b;
        box-sizing: border-box;
        border-radius: 8px;
        display: inline-block;
        padding-right: 25px;
        a {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 50px;
          align-items: center;
          letter-spacing: 0.5px;
          color: #fdfdff;
          &::before {
            content: "";
            width: 20px;
            height: 20px;
            right: 20px;
            top: 15px;
            z-index: 2;
            position: absolute;
            background-image: url("../../../../assets/images/ArrowLeftInSphere.svg");
            background-repeat: no-repeat;
            background-size: contain;
          }
          &:hover {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }
}
