.tooltip_panel_device {
    &.popover {
        max-width: 200px;
    }
    .arrow {
        position: absolute;
        top: 0px;
        width: 15px !important;
        display: block !important;
        height: 15px !important;
        transform-origin: center;
        transform: rotateZ(135deg) translate(16px, -4px) !important;
        background-size: cover !important;
        background-image: url("../../../../assets/images/arrowForTolltip.png") !important;
    }
    .popover-body {
        padding: 0 !important;
       
    }
    &-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
        &-img {
            position: relative;
            display: block;
            max-width: 200px;
            max-height: 200px;
            object-fit: cover;
            background: #000;
            border-radius: 5px 5px 0px 0px;
        }
        &-title {
            position: relative;
            display: block;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            line-height: 16px;
            letter-spacing: 0.2px;
            color: #4a9eea;
            padding-top: 8px;
            padding-left: 8px;
            padding-right: 8px;
        }
        &-desctiption {
            position: relative;
            width: 100%;
            height: 100%;
            display: block;
            padding: 5px 5px 15px 5px;
            font-size: 0.8rem;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            line-height: 12px;
            letter-spacing: 0.2px;
            color: rgba(37, 40, 43, 0.6);
        }
    }
}