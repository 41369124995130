.personal-page_setting{
    padding: 7vh 0 0 7vh;
    font-family: RobotoRegular;
    &_toggle{
        display: flex;
        &-link{
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 16px;
            margin-left: 15px;
            cursor: pointer;    
            opacity: 0.5;
            color:$w;
            &:hover{
                text-decoration: none;
                color: $w;
            }
        }
        &-active{
            color: $Government !important;
            opacity: 1 !important;
        }
    }
}
.person-area_setting-option{
    color: $w;
    width: 250px;
    padding: 30px 0 0 7vh;
    & p, & h4{
        padding: 8px;
        margin:0;
    }
    &-title{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 16px;
        letter-spacing: 0.2px;
        cursor: default;
    }
    &-your, &-set{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        cursor: default;
        letter-spacing: 0.2px;
    }
    &-set{
        color: $Government;
        cursor: pointer;
        width: 100%;
    }
}


.out{
    color: $Government;
    cursor: pointer;
}
.delite{
    color: #FF6262;
}