.pfml {
  &-container {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  &-sidebar {
    &-dot {
      &-position_btn {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #e8e8e8;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
        span {
          padding-top: 5px;
        }
        .button__option-menu {
          width: 90px;
          height: 24px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 12px;
          margin: 2px 10px;
          margin-top: 10px;
          &:disabled {
            color: #868686;
            background: #535353;
            border: 1px solid #868686;
            box-sizing: border-box;
            border-radius: 6px;
          }
        }
      }
      &-input {
        width: 185px;
        max-width: 185px;
        height: 26px;
        position: relative;
        &.custom_first_items_offset {
          width: 230px;
          max-width: 239px;
        }
      }
    }
    &-researche_env {
      margin-top: 10px;
      margin-left: 10px;
      ul {
        padding-left: 0;
        li {
          list-style: none;
          font-family: $fontRoboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.2px;
          margin-top: 10px;
        }
      }
    }
    &-wrapper_custom_inp {
      &-input_one {
        background: #37393a;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        border-radius: 1px;
        height: 26px;
        width: 250px;
        font-family: Roboto Condensed;
        background: #424242;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        border-radius: 1px;
        color: #fff;
        max-width: calc(100% - 50px);
        padding-left: 7px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        &:hover {
          background: #16191d;
        }
        &.double_lable {
          width: 190px;
        }
      }
      &-tree_inp {
        position: relative;
        margin: 7px;
        border: 1px solid #787575;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 3px;

        .sidebar__value {
          display: flex;
          justify-content: space-between;
          margin: 5px auto;
          .sidebar__value__input {
            width: 30%;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.2px;
            color: #e8e8e8;
            .wrapper__input {
              margin: 0;
              input {
                text-align: center;
              }
            }
          }
        }
      }
    }
    &-wrapper_custom_check_box {
      font-family: $fontRoboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      .default_components-help_text {
        margin-left: 13px;
        margin-bottom: 0;
        top: 0px;
        font-family: $fontRobotoCondensed;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #e8e8e8;
      }
    }
  }
  &-row_header {
    justify-content: space-between;
    padding: 0 1em;
    align-items: center;
    height: 40px;
    background: $darckThemeHeader;
    .col-12 {
      padding: 0px 0px 0px 0px !important;
      height: 40px;
    }
  }
  &-row_canvas {
    height: calc(100% - 40px);
    overflow: hidden;
  }
  &-header {
    &-logo {
      position: relative;
      left: -5px;
      width: 23px;
      height: 39px;
      display: inline-block;
      background-image: url("../../../../assets/images/logoGreen.png");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }
    &-menu-classic {
      position: fixed;
      display: block;
      height: 40px;
      top: 0px;
      left: 1.1%;
      ul {
        margin-top: 0px;
        padding-left: 2em;
        display: flex;
        top: 0px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 20px;
          right: 0px;
          top: 9px;
          background: rgba(255, 255, 255, 0.1);
        }
        &::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 20px;
          left: 23px;
          top: 8px;
          background: rgba(255, 255, 255, 0.1);
        }
        li {
          list-style: none;
          font-family: $fontRoboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 39px;
          letter-spacing: 0.2px;
          color: rgba(255, 255, 255, 0.8);
          // margin-right: 10px;
          padding-left: 10px;
          padding-right: 10px;
          opacity: 0.7;
          height: 40px;
          &:hover {
            background: #16191d;
          }
          &:focus {
            background: #16191d;
          }
          &::first-child {
            margin-right: 0px;
          }
          &::last-child {
            margin-right: 0px;
          }
        }
      }
    }
    &-menu-hidden {
      display: none;
    }
    &-menu-active {
      position: absolute;
      top: 40px;
      left: 5em;
      z-index: 1061 !important;
      width: 15em;
      height: 40px;
      display: block;
      height: auto;
      border-radius: 0px !important;
      background-color: $darckThemeHeader !important;
      ul {
        background-color: $darckThemeHeader;
        border-radius: 5px;
        padding-left: 10px !important;
        margin: 0;
        li {
          list-style: none;
          font-size: 14px;
          line-height: 30px;
          cursor: pointer;
          text-align: start;
          margin-left: 13px;
          letter-spacing: 0.2px;
          color: rgba(255, 255, 255, 0.8);
          &::first-child {
            margin-right: 0px;
            &::before {
              border-radius: 5px;
            }
          }
          &::last-child {
            margin-right: 0px;
            &::before {
              border-radius: 5px;
            }
          }
          &::after {
            content: "";
            position: absolute;
            background-image: url("../../../../assets/images/arrowDownWhite.png");
            background-size: 40%;
            transform: rotate(-90deg);
            width: 30px;
            height: 30px;
            background-position: center;
            background-repeat: no-repeat;
            right: 1em;
          }
          &:hover::before {
            content: "";
            background: $BlueEmpty;
            width: 100%;
            left: 0;
            height: 30px;
            opacity: 0.5;
            position: absolute;
          }
        }
      }
    }
    &-project_info {
      height: 40px;
      position: relative;
      line-height: 30px;
      left: 0;
      display: inline-block;
      @include xxl {
        left: -10%;
      }
      @include xl {
        left: -11%;
      }
      @include nb {
        left: -14%;
      }
      @include lg {
        left: -10%;
      }
      &-text {
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.2px;
        color: rgba(255, 255, 255, 0.5);
      }
      &-project_name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #ffffff;
        cursor: pointer;
        &::after {
          content: "";
          width: 10px;
          height: 10px;
          top: 11px;
          margin-left: 5px;
          position: absolute;
          background-image: url("../../../../assets/images/arrowDownWhite.png");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
        }
      }
    }
    &-profile {
      width: auto;
      height: 40px;
      display: flex;
      position: fixed;
      right: 300px;
      @include lg {
        right: 140px;
      }
      &-link_avatar {
        position: relative;
        background: $DefaultGreen;
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 25px;
        border-radius: 30px;
        cursor: pointer;
        font-size: 20px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.2px;
        padding-left: 5%;
        color: #ffffff;
        /* text-align: center !important; */
      }
      &-will_share {
        &-button {
          height: 30px;
          width: 106px;
          margin-top: 5px;
          margin-bottom: 5px;
          background: #4a9eea;
          border-radius: 4px;
          color: #fff;
          border: none;
          &:focus {
            outline: none;
          }
        }
      }
    }
    &-logout {
      width: 22px;
      height: 22px;
      margin-top: 7px;
      margin-bottom: 14px;
      border: none;
      background: none;
      background-image: url("../../../../assets/images/logout.png");
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
  &-drop_down_wrap_option {
    &-wrap {
      max-width: 325px;
      height: auto;
      color: $w;
    }
    &-header_text {
      font-size: 16px;
      font-family: $fontMonserat;
    }
    &-closed {
      position: absolute;
      margin: 0px 0px 0px 20px;
      cursor: pointer;
      top: 2%;
      right: 5%;
      width: 20px;
      height: 20px;
      display: inline-block;
      opacity: 0.5;
      background-image: url("../../../../assets/images/cansel.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      color: #fff;
    }
    &-type_task {
      margin: 15px 0 10px 0;
      &_control_value {
        border: 1px solid rgba(225, 225, 225, 0.2);
        margin: 5px 0;
        border-radius: 2px;
        & .form-group {
          margin-bottom: 0;
        }
      }
    }
    &-title-type_task {
      font-family: $fontRoboto;
      font-size: 14px;
    }
    &-label_control_input_value {
      display: flex;
      align-items: center;
      margin: 5px 0;
      font-family: $fontRobotoCondensed;
      font-size: 14px;
      & .default_components-radio_btn {
        margin-top: 2px;
      }
    }
    &-form_group_input_value {
      margin-bottom: 0;
    }
    &-control_input {
      display: inline-block;
      margin-bottom: 10px;
      & .wrapper__input {
        margin: 0;
      }
      & .input__data {
        width: 106px;
      }
    }
    &-type_task_control {
      border: 1px solid rgba(225, 225, 225, 0.2);
      margin: 5px 0 30px 0;
      padding: 5px;
      display: block;
      & .wrapper__input {
        margin: 2px 0;
        justify-content: left;
        & label {
          margin: 0 5px;
          min-width: 60px;
        }
      }
      & .input__data {
        width: 106px;
      }
    }
  }
  // background-color: #7a7a7a;
}
.col_pfml {
  position: relative;
  height: 100%;
  &-header-menu_left {
    width: 45px;
    background: #535353;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  }
  &-canvas {
    width: calc(100% - 345px);
  }
  &-sidebar {
    overflow: hidden;
    width: 300px;
    height: calc(100% - 40px);
    right: 0px;
    position: absolute;
    background: #535353;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  }
}

.pfml-drop-menu {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 5em;
  background-image: url("../../../../assets/images/open-menu-drop.svg");
}
.pfml-menu-active {
  width: 100%;
  position: absolute;
  left: 0;
  top: 40px;
  left: 0% !important;
  transform: translate(0px, 0px) !important;
  z-index: 1060 !important;
  display: block;
  max-width: 100% !important;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden;
  background: transparent;
  opacity: 0.7;
}
.pfml-menu-hidden {
  display: none;
}
.pfml-menu-burger-custom_translate {
  border-radius: 0px !important;
  background-color: $darckThemeHeader !important;
  z-index: 1070;
}
.pfml-menu-classic-custom_translate {
  border-radius: 0px !important;
  background-color: $darckThemeHeader !important;
  z-index: 1070;
}
