$leftBefore: 10px;
$bottomBefore: 0;
.personal_page {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  background-color: $darckThemeBody;
  &-navigations {
    max-height: 50%;
    overflow: hidden;
    min-height: 300px;
    border-right: 2px solid rgba(255, 255, 255, 0.1);
    @include md {
      max-height: 70%;
    }
    &-nav {
      position: relative;
      width: 100%;
      height: 100%;
      &:last-child {
        margin-bottom: 1rem;
      }
      &-profile {
        font-weight: bold;
        letter-spacing: 0.2px;
      }
      &-all_projects {
        &::before {
          content: "";
          position: absolute;
          width: 28px;
          height: 28px;
          left: 1rem;
          bottom: 1px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("../../../../assets/images/folder.png");
        }
      }
      &-my_projects {
        position: relative;
        height: 40px;
        top: 0px;
        a {
          top: -41px;
          &:hover {
            &::after {
              border: none !important;
            }
          }
        }
        &::before {
          content: "";
          position: relative;
          display: inline-block;
          top: 10px;
          width: 30px;
          height: 30px;
          left: 1rem;
          bottom: 1px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("../../../../assets/images/folder-cloud.png");
        }
        &:hover {
          .personal_page-navigations-nav-add_project {
            position: relative;
            display: inline-block;
            width: 17%;
            height: 100%;
            top: -80px;
            line-height: 1.2;
            cursor: pointer;
            text-align: center;
            font-size: 30px;
            font-weight: 100;
            left: 85%;
            background: #535353;
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
      &-add_project {
        position: relative;
        display: inline-block;
        width: 15%;
        height: 100%;
        top: -80px;
        line-height: 1.2;
        text-align: center;
        font-size: 30px;
        font-weight: 100;
        left: 85%;
        background: #535353;
        color: rgba(255, 255, 255, 0.5);
        left: 100%;
        transition: 0.5s;
        z-index: 99;
        &:hover {
          .personal_page-navigations-nav {
            & a {
              text-decoration: none;
              list-style: none;
              font-weight: bold;
              color: #fff !important;
              &::after {
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 5px;
                position: absolute;
                border-right: 1px solid $BlueEmpty;
                background-color: rgba(255, 255, 255, 0.1) !important;
              }
            }
          }
        }
      }
      &-favorite {
        &::before {
          content: "";
          position: absolute;
          width: 30px;
          height: 30px;
          left: 1rem;
          bottom: 1px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("../../../../assets/images/circle-star.png");
        }
      }
      &-search {
        &::before {
          content: "";
          position: absolute;
          width: 30px;
          height: 30px;
          left: 1rem;
          bottom: 1px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("../../../../assets/images/search.png");
        }
      }
      &-trash {
        &::before {
          content: "";
          position: absolute;
          width: 30px;
          height: 30px;
          left: 1rem;
          bottom: 1px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("../../../../assets/images/trash-empty.png");
        }
      }
      &-settings {
        &::before {
          content: "";
          position: absolute;
          width: 30px;
          height: 30px;
          left: 1rem;
          bottom: 1px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("../../../../assets/images/settings.png");
        }
      }
      &.active {
        &::after {
          content: "";
          width: 3000px;
          height: 100%;
          left: 0;
          top: 5px;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
      a {
        position: relative;
        display: inline-table;
        width: 100%;
        height: auto;
        padding: 1rem 1.1rem 0.3rem 4rem; //1.1rem 1rem 1.3rem 2rem;
        color: #fff;
        text-decoration: none;
        list-style: none;
        margin: 2px 0;
        &:hover {
          text-decoration: none;
          list-style: none;
          font-weight: bold;
          color: #fff !important;
          &::after {
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 5px;
            position: absolute;
            border-right: 1px solid $BlueEmpty;
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
  }
  &-buttons {
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    border-right: 2px solid rgba(255, 255, 255, 0.1);
    // height: calc(100% - 355px);
    height: 100%; //calc(62% + 6px);
    position: relative;
    bottom: 0;
    &-create_project {
      position: relative;
      width: 90%;
      height: 40px;
      left: 5%;
      top: 1.1rem;
      letter-spacing: 1.25px;
      color: #4a9eea !important;
      font-size: 16px !important;
      font-weight: bold !important;
      border: 1px solid #4a9eea !important;
      border-radius: 4px !important;
      &:hover {
        color: #fff !important;
      }
    }
  }
  &-content {
    padding: 0 3%;
    max-height: 100%;
    width: 100%;
    @include sm {
      padding: 3%;
    }
    &-wrapper {
      position: relative;
      height: 90%;
      max-height: 90%;
      overflow: hidden;
    }
    &-heading {
      position: relative;
      margin-top: 40px;
      font-weight: bold;
      color: $w;
    }
    &-control_view_grid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 2.5em 0 0;
      &-buttons {
        position: relative;
        left: 0px;
        display: inline-block;
        &-view_grid {
          position: relative;
          height: 30px;
          width: 30px;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
          background-size: 107%;
          display: inline-block;
          background-image: url("../../../../assets/images/viewGrid.svg");
          border: 1px solid rgba(37, 40, 43, 1);
          &.active {
            border: 1px solid #4a9eea;
            box-sizing: border-box;
            border-radius: 2px;
          }
        }
        &-view_list {
          position: relative;
          height: 30px;
          width: 30px;
          margin-left: 15px;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
          background-size: 107%;
          display: inline-block;
          background-image: url("../../../../assets/images/viewList.svg");
          border: 1px solid rgba(37, 40, 43, 1);
          &.active {
            border: 1px solid #4a9eea;
            box-sizing: border-box;
            border-radius: 2px;
          }
        }
      }
      &-sorts {
        position: absolute;
        right: 0px;
        height: 30px;
        width: 15%;
        left: 85%;
        display: inline-block;
        @include vg {
          left: 75% !important;
        }
        @include md {
          left: 75% !important;
        }
        &-select_filters {
          min-width: 170px;
          left: -62px;
          position: relative;
          text-align: right;
          background: none;
          border: none;
          color: #fff;
          &:focus {
            outline: none;
          }
        }
        &-icon {
          position: relative;
          height: 30px;
          width: 30px;
          // left: 48%;
          top: 10px;
          margin-left: 5px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 70%;
          display: inline-block;
          background-image: url("../../../../assets/images/arrowFilterLeftDark.svg");
        }
        &-icon_straight {
          position: absolute;
          height: 18px;
          top: 15px;
          left: 98%;
          border-left: 2px solid rgba(255, 255, 255, 0.5);
          margin-right: 5px;
          display: block;
          font-size: 20px;
        }
        &-icon_arrow {
          position: absolute;
          height: 30px;
          width: 30px;
          top: 9px;
          left: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 65%;
          display: inline-block;
          background-image: url("../../../../assets/images/arrowRightFilterDark.svg");
        }
      }
    }
    &-content_wrap_grid {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: baseline;
      margin-top: 30px;
      max-height: 100%;
      overflow-y: scroll;
      @include lg {
        justify-content: center;
      }
      &::-webkit-scrollbar {
        width: 5px !important;
        &:hover {
          /* Track */
          &::-webkit-scrollbar-track {
            background: #1f2429 !important;
          }
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #d8d8d8 !important;
          }
          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555 !important;
          }
        }
      }
      .folder {
        &-wrapper {
          position: relative;
          margin: 5px 5px 5px 5px;
          overflow: hidden;
          width: 152px;
          height: auto; //130px;
          background-position: 50% 17%;
          background-repeat: no-repeat;
          background-size: 47% 43%;
          display: inline-block;
          background-image: url("../../../../assets/images/folder-empty.png");
          &:hover {
            background: $folderBlackHover;
            .folder-name-textarea {
              background: $folderBlackHover;
              &::-webkit-scrollbar {
                width: 5px !important;
              }
              /* Track */
              &::-webkit-scrollbar-track {
                background: $folderBlackHover !important;
              }
              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: $folderBlackHover !important; //#525252
              }
              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: $folderBlackHover !important;
              }
            }
            .folder-context_menu {
              display: block;
            }
          }
          &.empty {
            overflow: hidden;
            width: 152px;
            min-height: 130px;
            height: auto; // 130px;
            background-position: 50% 17%;
            background-repeat: no-repeat;
            background-size: 47% 43%;
            display: inline-block;
            background-image: url("../../../../assets/images/folder-empty.png");
          }
          &.filled {
            overflow: hidden;
            width: 152px;
            min-height: 130px;
            height: auto; // 130px;
            background-position: 50% 17%;
            background-repeat: no-repeat;
            background-size: 47% 43%;
            display: inline-block;
            background-image: url("../../../../assets/images/folder-full.png");
          }
        }
        &-star {
          position: absolute;
          width: 25px;
          height: 25px;
          background: #ffbc1f;
          border-radius: 30px;
          left: 95px;
          top: 50px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 70% 70%;
          display: inline-block;
          background-image: url("../../../../assets/images/star.png");
        }
        &-name {
          color: $w;
          font-style: normal;
          padding: 5px 10px 0px 10px;
          word-break: break-word;
          bottom: -75px;
          position: relative;
          overflow-x: hidden;
          &-textarea {
            position: relative;
            height: 100%;
            width: 100%;
            font-size: 13px;
            height: 30px;
            line-height: 14px;
            text-align: center;
            letter-spacing: 0.2px;
            font-weight: bold;
            background: transparent;
            border: none;
            color: $w;
            resize: none;
            &::-webkit-scrollbar {
              width: 5px !important;
            }
            /* Track */
            &::-webkit-scrollbar-track {
              background: transparent !important;
            }
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: transparent !important; //#525252
            }
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #555 !important;
            }
            &:focus {
              outline: none;
            }
            &.active {
              &:focus {
                outline: 1px solid $w;
              }
            }
          }
          &:hover {
            &::-webkit-scrollbar-thumb {
              background: #555 !important;
            }
          }
        }
        &-date {
          font-size: 11px;
          line-height: 15px;
          text-align: center;
          letter-spacing: 0.2px;
          bottom: -70px;
          position: relative;
          color: #969899;
        }
        &-context_menu {
          &:hover {
            background: rgba(255, 255, 255, 0.1);
            background-image: url("../../../../assets/images/context_menu_folder.png");
            background-size: contain;
          }
          &:focus {
            background: rgba(255, 255, 255, 0.1);
            background-image: url("../../../../assets/images/context_menu_folder.png");
            background-size: contain;
            display: block;
            .folder-wrapper {
              background: #16191d;
              border-radius: 4px;
            }
          }
          display: none;
          position: absolute;
          width: 25px;
          height: 25px;
          right: 5%;
          top: 4%;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.05);
          box-sizing: border-box;
          border-radius: 2px;
          background-image: url("../../../../assets/images/context_menu_folder.png");
          background-size: contain;
        }
      }
    }
    &-content_wrap_list {
      position: relative;
      margin-top: 30px;
      ::-webkit-scrollbar:hover {
        width: 10px !important;

        /* Track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1 !important;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #d8d8d8 !important;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555 !important;
        }
      }
    }
  }
}

.dark_theme {
  background: $darckThemeHeader !important;
  & .vls-drop_down_wrap_option-wrap_confirm {
    position: unset;
    margin-top: 20px;
  }
}
.context_menu {
  &-wrap {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    box-sizing: border-box;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    .arrow {
      &::after {
        border-bottom-color: #37393a !important;
      }
      &::before {
        border-bottom-color: #37393a !important;
      }
    }
    ul {
      position: relative;
      padding-left: 0px !important;
      margin-bottom: 0rem !important;
      li {
        a {
          color: #fff;
          &:hover {
            text-decoration: none;
          }
        }
        list-style: none;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        padding: 5px 5px 5px 5px;
        cursor: pointer;
        &:hover {
          &::before {
            content: "";
            width: 1000px;
            left: -100px;
            position: absolute;
            height: 25px;
            margin-top: -3px;
            background: #4a9eea;
            z-index: -1;
          }
        }
      }
    }
    .context_menu-delete_button {
      color: #ff6262 !important;
      &:hover {
        &::before {
          content: none;
        }
      }
    }
    .context_menu-border {
      &::before {
        content: "";
        width: 1000px;
        left: -100px;
        position: absolute;
        height: 1px;
        margin-top: -3px;
        background: rgba($color: #fff, $alpha: 0.1);
        z-index: -1;
      }
    }
    .context_menu-border {
      &:hover {
        &::before {
          content: "";
          width: 1000px;
          left: -100px;
          position: absolute;
          height: 1px;
          margin-top: -3px;
          background: rgba($color: #fff, $alpha: 0.1);
          z-index: -1;
        }
      }
    }
  }
}
.closed_context_menu {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.folder_list {
  height: 30px;
  &-name {
    color: $w;
    font-style: normal;
    padding: 5px 10px 0px 10px;
    word-break: break-word;
    position: relative;
    width: 100%;
    // height: 30px;
    display: inline-block;
    overflow-x: hidden;
    &-textarea {
      position: relative;
      height: 100%;
      width: 80%;
      font-size: 13px;
      height: 30px;
      line-height: 14px;
      text-align: left;
      margin-left: 40px;
      letter-spacing: 0.2px;
      font-weight: bold;
      background: transparent;
      border: none;
      color: $w;
      resize: none;
      &::-webkit-scrollbar {
        width: 5px !important;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent !important;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: transparent !important; //#525252
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
      }
      &:focus {
        outline: none;
      }
      &.active {
        &:focus {
          outline: 1px solid $w;
        }
      }
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: #555 !important;
      }
    }
    &.empty {
      background-repeat: no-repeat;
      background-size: 40px 30px;
      background-image: url("../../../../assets/images/folder-empty.png");
    }
    &.filled {
      background-repeat: no-repeat;
      background-size: 40px 30px;
      background-image: url("../../../../assets/images/folder-full.png");
    }
    &.star {
      &::before {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        left: 28px;
        top: 20px;
        background: #ffbc1f;
        border-radius: 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70% 70%;
        background-image: url("../../../../assets/images/star.png");
      }
    }
  }
  &-date {
    line-height: 32px;
  }
  &-size {
    line-height: 32px;
  }
  &-type {
    line-height: 32px;
  }
  &-context_menu {
    outline: none !important;
    &:hover {
      // background: rgba(255, 255, 255, 0.1);
      background-image: url("../../../../assets/images/menu.png");
      background-repeat: no-repeat;
      opacity: 1;
      background-size: contain;
    }
    &:focus {
      // background: rgba(255, 255, 255, 0.1);
      background-image: url("../../../../assets/images/menu.png");
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 1;
      outline: none;
      display: block;
      // .folder-wrapper {
      //   background: #16191d;
      //   border-radius: 4px;
      // }
    }
    height: 25px;
    right: 5%;
    top: 4%;
    background: none;
    border: none;
    box-sizing: border-box;
    // border-radius: 2px;
    opacity: 0.5;
    background-image: url("../../../../assets/images/menu.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

//activeClass in PersonalPageLayout
.page-navigations-active {
  &::after {
    content: "";
    width: 3000px;
    height: 100%;
    left: 0;
    top: 5px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.setting {
  &-content-header {
    position: relative;
    width: 90%;
    left: 5%;
    right: 5%;
    top: 2.5rem;
    border-radius: 5px;
    height: 150px;
    background-color: #16191e;
    overflow: hidden;
    color: $w;
    &_text_welcome-top {
      text-align: center;
      margin: 0.75em 0 0.5em 0;
      color: $w;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.2px;
      color: #b3d8ff;
    }
    &_text_welcome-center {
      position: relative;
      left: 200px;
      width: calc(100% - 200px);
      text-align: center;
      top: 4rem;
      color: $w;
    }
    & .auth_and_reg-left_column-bottom_animate_img {
      width: 15em;
      height: 15em;
      background-size: cover;
      top: 0;
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   // top: 6px;
    //   min-width: 200px;
    //   height: 100%;
    //   // left: 1.1rem;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   background-image: url("../../../../assets/images/Group.png");
    // }
  }
}
.personal-page {
  &_my-rate {
    text-align: center;
    margin: 15% auto;
    & .title-of-rate {
      color: #ff6262;
    }
  }
  &-content-header {
    position: relative;
    width: 90%;
    left: 5%;
    right: 5%;
    top: 2.5rem;
    border-radius: 5px;
    height: 150px;
    color: $w;
    background-color: #16191e;
    overflow: hidden;
    &_text_welcome-top {
      text-align: center;
      margin: 0.75em 0 0.5em 0;
      color: $w;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.2px;
      color: #b3d8ff;
    }
    &_text_welcome-center {
      position: relative;
      left: 200px;
      width: calc(100% - 200px);
      text-align: center;
      top: 4rem;
      color: $w;
    }
    &::before {
      content: "";
      position: absolute;
      top: 6px;
      min-width: 200px;
      height: 100%;
      left: 1.1rem;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("../../../../assets/images/ilustrations.png");
    }
    &_special-offer {
      width: 300px;
      margin: 0 auto;
      font-family: MontserratRegular;
      text-align: center;
      & p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.2px;
        margin: 0;
      }
      & span {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
      }
      & .button__download {
        width: 14em;
      }
    }
  }
}

// page search
.wrapper-search-page {
  height: 90%;
  & .count-projects {
    margin-left: 12px;
    color: $Government;
  }
}

.personal_page {
  &-search-project {
    width: 90%;
    display: flex;
    align-items: center;
    height: 50px;
    margin: 2% auto;
    padding: 10px 10px 0 50px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    & input {
      border: none;
      width: 100%;
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
      font-family: $fontMonserat;
      background: transparent;
      color: $w;
    }
  }
  &-done-search {
    font-family: $fontMonserat;
    font-style: normal;
    margin: 0 4vw;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: $w;
  }
  & .search-project-result {
    border: none;
    position: relative;
    top: -1em;
    left: -2em;
    background: transparent;
    &:hover,
    &:focus {
      outline: none;
    }
    &::before {
      content: '';
      position: absolute;
      background-image: url(../../../../assets/images/searchPersonalPage.svg);
      width: 30px;
      height: 30px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      @include md {
        width: 25px;
        height: 25px;
      }
    }
  }
  &_drop-sidebar {
    &.active {
      display: none;
      @include sm {
        display: block;
        position: absolute;
        top: 5px;
        left: 10px;
      }
    }
    width: 40px;
    height: 40px;
    display: none;
    background-image: url('../../../../assets/images/open-menu-drop.svg');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.wrapper-scroll_wrap-grid {
  position: relative;
  margin-top: 30px;
  height: 90%;
}
.personal_page_drop-sidebar.active {
  z-index: 10;
}
