@mixin inputCustom {
  width: calc(100% - 60px);
  position: relative;
  color: #495057;
  background: #f3f3f4;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  box-shadow: 0 0 0 0.2rem rgb(255, 255, 255);
  &:hover {
    color: #495057;
    background-color: #fff;
    // border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border: none;
  }
  &:active {
    box-shadow: none;
    border: none;
  }
}
@mixin h4CustomTitle {
  padding-left: 30px;
  font-family: $fontMonserat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #25282b;
}

.modal {
  &-wrap {
    top: 42% !important;
    left: -5% !important;
    &-backdrop {
      background: #001a34 !important;
      opacity: 0.7 !important;
    }
    .modal {
      &-content {
        width: 406px;
        height: 190px;
        background: #37393a;
        box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04),
          -12px -12px 24px rgba(35, 111, 153, 0.04);
        border-radius: 4px;
      }
    }
  }
  &-close_btn {
    position: absolute;
    opacity: 1;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-image: url("../../../../assets/images/cansel.svg");
  }
  &-icon {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 45px;
    left: 30px;
  }
  &-text_info_block {
    position: absolute;
    width: 275px;
    height: 60px;
    top: 50px;
    right: 30px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    align-items: center;
    letter-spacing: 0.2px;
    color: #ffffff;
  }
  &-confirm_component_wrap {
    position: absolute;
    bottom: 30px;
    left: 65px;
  }
  &-btn_success {
    background: #4a9eea;
    border-radius: 4px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 1.25px;
    color: #ffffff;
    border: none;
    position: absolute;
    width: 133px;
    height: 40px;
    top: 120px;
    right: 30px;
  }
  &-load {
    &-text {
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #ffffff;
    }
  }
}
.wrapper-modal-setting {
  background: #001a34;
  opacity: 0.7;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 70;
  font-family: $fontMonserat;
  & h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }
}
.modal-setting {
  position: fixed;
  top: 30%;
  left: 35%;
  opacity: 1;
  background: $w;
  padding: 30px 40px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  color: #25282b;
  z-index: 90;
  width: 30em;
  @include md {
    left: 10%;
  }
  &_form-close {
    width: 20px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    // top: -1vh;
    left: 95%;
    height: 20px;
    opacity: 1;
    background-repeat: no-repeat;
    background-image: url("../../../../assets/images/closeModalWhite.svg");
  }
  &-title {
    font-family: MontserratRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.2px;
  }
  & .wrapper-button-group {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & button {
      height: 40px;
    }
    & p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      color: #4a9eea;
      margin: 0;
      cursor: pointer;
    }
  }
}
.modal-out {
  color: $Government;
  cursor: pointer;
}
.custom_size {
  &-for {
    &-modal_perosnal_page {
      width: 480px;
      height: 208px;
      background: #ffffff;
      box-shadow: -12px -12px 24px rgba(35, 111, 153, 0.04),
        12px 12px 24px rgba(35, 111, 153, 0.04);
      border-radius: 4px;
    }
  }
}
.modal {
  &-change_name {
    &-wrapper {
      padding: 12px;
      width: 406px;
      height: 207px;
      &-wrapper_fields {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      h4 {
        @include h4CustomTitle;
      }
      .input_form {
        @include inputCustom;
      }
    }
  }
  &-change_password {
    &-wrapper {
      padding: 12px;
      width: 406px;
      height: 267px;
      h4 {
        @include h4CustomTitle;
      }
      &-wrapper_fields {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-wrapper_custom_field {
          position: relative;
          width: calc(100% - 60px);
          &-eye {
            position: absolute;
            width: 22px;
            height: 19px;
            top: 33%;
            right: 4%;
            cursor: pointer;
            z-index: 99;
            &.hidden_pass {
              background-repeat: no-repeat;
              display: inline-block;
              background-size: 100%;
              background-image: url("../../../../assets/images/eyeHidden.png");
            }
            &.visible_pass {
              background-repeat: no-repeat;
              display: inline-block;
              top: 36%;
              background-size: 100%;
              background-image: url("../../../../assets/images/eyeVisible.png");
            }
          }
        }
        &-password_custom_field {
          width: 100% !important;
        }
      }
      .input_form {
        @include inputCustom;
      }
      .wrapper-button-group {
        padding-left: 34px;
        padding-right: 34px;
        padding-top: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        span {
          display: inline-block;
          font-family: $fontMonserat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #4a9eea;
          cursor: pointer;
        }
        button {
          width: 133px;
          height: 40px;
        }
      }
    }
    .modal-setting_form-close {
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: url("../../../../assets/images/closeModalWhite.svg");
    }
  }
}
