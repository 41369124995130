.wrapper-for-content{
  width: fit-content;
  position: absolute;
  top: 10%;
  right: 25%;
  background: #000;
  height: auto;
  padding: 1em;
  margin: 0 auto;
  &-content{
    &-closed{
      position: relative;
      margin: 0px 0px 0px 20px;
      cursor: pointer;
      top: 2%;
      width: 20px;
      height: 20px;
      display: inline-block;
      opacity: 0.5;
      background-image: url("../../../../assets/images/cansel.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      color: #fff;
    }
  }
}