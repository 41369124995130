.default_components {
  &_tooltip {
    background: #6979f8;
    border-radius: 8px;
    width: auto;
    padding: 5px;
    max-width: 180px;
    height: auto;
    font-family: MontserratRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #fbfbfb;
    position: relative;
    top: 83px;
    right: 40%;
    & p {
      margin: 0;
      padding: 0;
    }
    &_arrow {
      width: 10px;
      height: 10px;
      position: relative;
      top: -11px;
      left: 45%;
      background: url("../../../../assets/images/arrowDown.png");
      transform: rotate(-50deg);
    }
  }
  &-radio_btn {
    height: 20px;
    width: 27px;
    margin-top: 5px;
    input[type="radio"] {
      background: #424242;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      box-shadow: inset -3px -3px 10px rgba(75, 85, 116, 0.3), inset 3px 3px 10px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      width: 20px;
      cursor: pointer;
      display: inline-block;
      height: 20px;
      margin-right: 1px;
      position: relative;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      & p {
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
      }
    }
    input[type="radio"]:after {
      background: transparent;
      border-radius: 25px;
      content: "";
      display: block;
      height: 12px;
      position: relative;
      width: 12px;
      justify-content: center;
      left: 17%;
      top: 17%;
    }
    input[type="radio"]:checked:after {
      background: #4a9eea;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 25px;
      content: "";
      display: block;
      height: 12px;
      position: relative;
      width: 12px;
    }
  }
  &-button {
    &:hover {
      background-image: url("../../../../assets/images/menu.png");
      background-size: contain;
      opacity: 1;
    }
    &:focus {
      background-repeat: no-repeat;
      opacity: 1;
      background-image: url("../../../../assets/images/menu.png");
      outline: none;
    }
    position: absolute;
    height: 25px;
    //   right: 5%;
    //   top: 4%;
    background: none;
    border: none;
    opacity: 0.5;
    box-sizing: border-box;
    border-radius: 2px;
    background-repeat: no-repeat;
    background-image: url("../../../../assets/images/menu.png");
    background-size: contain;
  }
  &-help_text {
    position: relative;
    display: inline-block;
    top: 5px;
    font-family: $fontRobotoCondensed !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: rgba(232, 232, 232, 0.3);
    width: 277px;
  }
}
.default_button {
  &:hover {
    background: none;
  }
  &:focus {
    border: none;
    outline: none;
    box-shadow: none !important;
  }
}
.btn {
  &:focus {
    box-shadow: none !important;
  }
}
/* для элемента input c type="checkbox" */
.custom-checkbox {
  z-index: -1;
  opacity: 0;
}

/* для элемента label, связанного с .custom-checkbox */
.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  margin: 0;
}

/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20% 20%;
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 2px;
}

/* стили при наведении курсора на checkbox */
.custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
  border-color: #b3d7ff;
}

/* стили для активного чекбокса (при нажатии на него) */
// .custom-checkbox:not(:disabled):active + label::before {
//   // background-color: #b3d7ff;
//   // border-color: #b3d7ff;
// }

/* стили для чекбокса, находящегося в фокусе */
.custom-checkbox:focus + label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox:focus:not(:checked) + label::before {
  border-color: #80bdff;
}

/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox:checked + label::before {
  background-color: #4a9eea;
  border: 1px solid #3d88cc;
  border-radius: 2px;
  background-image: url("../../../../assets/images/arrowCheckBox.svg");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}

/* стили для чекбокса, находящегося в состоянии disabled */
.custom-checkbox:disabled + label::before {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 2px;
}
.confirm_component {
  &-wrap {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 1.25px;
    display: flex;
    /* Color black title text */

    color: #25282b;
  }
  &-cancellation {
    width: 133px;
    height: 40px;
    background: #ebecf0;
    border-radius: 4px;
    border: none;
    margin-right: 15px;
  }
  &-ok {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 1.25px;
    color: #ffffff;
    width: 133px;
    height: 40px;
    background: #4a9eea;
    border-radius: 4px;
    border: none;
  }
  &-loader {
    width: 30px !important;
    height: 30px !important;
    left: 35%;
  }
}

// стили для всех кнопок
.button {
  border: none;
  border-radius: 4px;
  font-family: MontserratRegular;
  padding: 5px 10px;
  margin: 5px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  width: 100%;
  &:active,
  &:focus {
    outline: none;
  }
  &__download {
    background: #ffa62f;
    &:hover {
      box-shadow: 0px 6px 12px #ffa62f;
    }
  }
  &__cansel {
    background: #ebecf0;
    &:hover {
      box-shadow: 0px 6px 12px #ebecf0;
    }
  }
  &__ok {
    color: $w;
    background: $BlueEmpty;
    &:hover {
      box-shadow: 0px 6px 12px $BlueEmpty;
    }
  }
  &__option-menu {
    background: #535353;
    border: 1px solid $w;
    box-sizing: border-box;
    border-radius: 6px;
    display: block;
    font-family: MontserratRegular;
    letter-spacing: 1.25px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin: 20px 10px;
    padding: 5px;
    color: $w;
    &:focus {
      color: #25282b;
      background: $w;
    }
  }
  &__tab {
    background: #424242;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: inset 3px 3px 10px rgba(75, 85, 116, 0.3), inset -3px -3px 10px rgba(75, 85, 116, 0.1);
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    margin: 5px 0;
    padding: 5px 9px;
    width: 100%;
    color: #787575;
  }
  &__active-btn {
    background: $Government;
    box-shadow: -2px -1px 4px rgba(0, 0, 0, 0.25), 2px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    color: $w;
    font-family: RobotoRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    margin: 5px 0;
    padding: 5px 9px;
    width: 100%;
  }
}
//Toggle Switcher
.toggle-switcher {
  display: flex;
  width: 283px;
  justify-content: space-around;
  color: $colorForSidebarText;
  padding: 10px 0;
  margin: 0 auto;
  & p {
    display: block;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    margin: 0 10px;
  }
}
.toggle-switcher__toggle-control {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  width: 40px;
  user-select: none;
  .toggle-control__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .toggle-control__input:checked ~ .toggle-control {
    &:after {
      left: 36px - 18px;
    }
  }
  .toggle-control__input:checked ~ .variable {
    color: $BlueEmpty;
  }
  .toggle-control {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 36px;
    border: 1px solid $borderForSidebar;
    border-radius: 9px;
    background-color: $darkInput;
    transition: background-color 0.15s ease-in;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      border-radius: 9px;
      background: white;
      transition: 0.15s ease-in;
      border: 1px solid #3d88cc;
      background: $BlueEmpty;
      &:hover {
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
.toggle-switcher {
  width: 285px;
  & .active {
    color: $Government;
  }
}
.toggler-switcher-for-setting {
  display: block;
  margin: 5em;
  color: $w;
  & .toggle-control {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 52px;
    border-radius: 20px;
    background: url(../../../../assets/images/evening-55067_640.png);
    background-size: cover;
    background-position: center;
    transition: 0.15s ease-in;
    box-shadow: inset 3px 3px 10px rgba(75, 85, 116, 0.3), inset -3px -3px 10px rgba(75, 85, 116, 0.1);
    border: none;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      width: 24px;
      height: 24px;
      border-radius: 20px;
      background: url("../../../../assets/images/moon-1859616_1920.png");
      background-position: center;
      transition: 0.15s ease-in;
      border: none;
    }
  }
  .toggle-control__input:checked ~ .toggle-control {
    &:after {
      left: 52px - 24px;
    }
  }
}
// charts
.wrapper-charts {
  width: auto;
  height: auto;
}
