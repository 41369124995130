@font-face {
  font-family: "a_lcdnova";
  src: url("../../../fonts/a_lcdnova.ttf");
}
canvas {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Montserrat",
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "a_lcdnova", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="number"] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -moz-appearance: textfield;
  }
}

body {
  margin: 0;
  font-family: a_lcdnova "Roboto Condensed", sans-serif -apple-system,
    BlinkMacSystemFont, "Roboto", "Montserrat", "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "a_lcdnova",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $gr;
  /* width */
  // &:last-child {
  //   cursor: url("../../../assets/images/cursor.png") !important;
  // }
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d8d8d8 !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.code-box-demo {
  text-align: center;
}

.dg li.title {
  overflow: hidden;
}

.ant-layout-has-sider {
  height: 85%;
}
.ant-form-item {
  padding-bottom: 0 !important;
}

.popup-3d {
  color: #fff;
  font-family: "Pacifico", cursive;
  font-size: 10rem;
  pointer-events: none;
}
.wrapper-sidebar-drop {
  &.active {
    @include sm {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $black;
      opacity: 0.2;
      z-index: 500;
    }
  }
}
.personal_page-sidebar {
  padding-right: 0px;
  padding-left: 0px;
  width: 290px;
  max-width: 290px;
  height: 100%;
  &.active {
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  @include sm {
    background: $black;
    position: absolute;
    left: -300px;
    z-index: 501;
  }
}
.empty_avatar {
  &-personal_page {
    &::before {
      content: "Y";
      position: absolute;
      width: 30px;
      height: 30px;
      line-height: 29px;
      font-size: 17px;
      left: 1rem;
      text-align: center;
      bottom: 1px;
      border-radius: 30px;
      background-color: #4a9eea;
      //todo: Сделать загрузку аватарки
    }
  }
}
.question_helpers {
  display: flex;
  align-items: center;
  text-align: center;
  &-button {
    color: #fff;
    position: fixed;
    right: 3%;
    bottom: 3%;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    background: #16191d;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    cursor: pointer;
    font-family: $fontRoboto;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 0.2px;
    color: rgba(255, 255, 255, 0.5);
  }
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
  max-height: 100%;
}
.popover-body {
  overflow: hidden;
  & .nav-link {
    margin: 0;
    padding: 0;
  }
}
.overlay_trigger {
  background: #37393a !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &-ul {
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 10px !important;
    margin-bottom: 0px !important;
    color: #fff;
    li {
      cursor: pointer;
      &.active {
        &::after {
          content: "";
          position: absolute;
          width: 30px;
          right: 0px;
          // top: 26px;
          height: 30px;
          background-size: 100% 100%;
          background-image: url("../../../assets/images/check_mark.png");
        }
      }
      &:hover {
        &::before {
          content: "";
          position: absolute;
          width: 1000px;
          height: 30px;
          left: -100px;
          background: #25282b;
          z-index: -1;
        }
      }
      z-index: 999999999999;
      line-height: 31px;
      list-style: none;
      height: 30px;
      padding-right: 30px;
    }
  }
  .arrow {
    display: none !important;
  }
}
.theme {
  &-darck {
    color: #fff !important;
  }
}
.test-wrap {
  position: relative;
  width: 100%;
  height: 700px;
}
.folder {
  .dg {
    ul {
      overflow-y: scroll;
      /* width */
      &::-webkit-scrollbar {
        width: 5px !important;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #202020 !important;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #d8d8d8 !important;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
      }
    }
  }
}
.add_folder {
  padding-bottom: 10px !important;
}
.wrapper-context-menu {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  & .context-menu {
    height: auto;
    max-width: 500px;
    background: #37393a;
    color: $w;
    cursor: pointer;
    & li {
      list-style-type: none;
      &:hover {
        background: $Government;
      }
    }
  }
  .arrow {
    position: absolute;
    left: 0px;
    // top: -10px !important;
    transform: translate(95px, 0px);
    // top: calc(-0.5rem - 1px);
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
    &::after {
      border-bottom-color: #37393a !important;
    }
    &::before {
      border-bottom-color: #37393a !important;
    }
  }
}
.popover .arrow {
  position: absolute;
  left: 0px;
  // top: -10px !important;
}

.dg .c input[type="checkbox"] {
  margin-top: 7px;
  left: 90%;
  position: absolute;
}
.bs-popover-right-start {
  // margin-left: -20px !important;
  top: 2em;
  & .arrow {
    display: none !important;
  }
}
.auth_and_reg-left_column-col_l {
  &.col-3 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
.auth_and_reg-right_column-col_r {
  &.col-9 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

