.ant-menu .ant-menu-light .ant-menu-root .ant-menu-horizontal {
  background-color: #1890ff;
  color: #fff !important;
}
.ant-menu-horizontal {
  background-color: #1890ff;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected:hover {
  color: #fff !important;
  border-bottom: 2px solid #fff !important;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #fff !important;
}
.ant-menu-horizontal > .ant-menu-item > a {
  color: #fff !important;
}
.navbar{
  padding: 0.1rem 1rem !important;
}
.header {
  &-person_page{
    background-color: $darckThemeHeader !important;
  }
  &-navigations{
    &-outin{
      position: absolute;
      right: 1%;
      top: 6px;
      width: 30px;
      height: 30px;
      background-image: url("../../../../assets/images/logout.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &-navbar_brand {
    color: #fff !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    &-logo {
      width: 30px;
      height: 30px;
      background-image: url("../../../../assets/images/logoGreen.png");
      background-repeat: no-repeat;
      background-size: 75%;
      background-position: center;
    }
  }
  &-menu__nav_link {
    color: #000000;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      color: #337dff;
    }
  }
}
