.error {
  &-input {
    background: #ffffff;
    border: 2px solid #ff6262 !important;
    box-sizing: border-box;
    border-radius: 4px;
    &:hover {
      color: #495057 !important;
      background-color: #fff !important;
      border: 2px solid #ff6262 !important;
      outline: 0 !important;
      box-shadow: 0 0 0 0.2rem rgba(255, 98, 98, 0.15) !important;
    }
    &:focus {
      color: #495057;
      background: #ffffff !important;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(255, 98, 98, 0.15) !important;
    }
  }
  &-text_field {
    color: #ff6262;
  }

  &-block_header {
    position: relative;
    width: 100%;
    height: auto;
    background: #ff6262;
    z-index: 2;
    display: flex;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;
    justify-content: center;
    line-height: 40px;
    &-text {
      position: relative;
      width: 90%;
      height: auto;
      text-align: center;
      // left: 5%;
      display: inline-block;
      @include xxs {
        left: 0%;
        right: 10%;
      }
    }
    &-closed_btn {
      position: relative;     
      width: 20px;
      height: 20px;
      background-color: none;
      background: none;
      border: none;
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: url("../../../../assets/images/cansel.svg");
    }
  }
}
