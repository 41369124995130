.faq_page {
  &-wrap {
    position: relative;
    width: 100%;
    min-height: 100vh;
    &-title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 46px;
      letter-spacing: 0.2px;
      color: #25282b;
      text-align: center;
      margin-top: 50px;
    }
    &-text_under_title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      color: rgba($color: #25282b, $alpha: 0.5);
      display: block;
      span {
        color: #007eff;
      }
    }
    &-content_wrap {
      position: relative;
      width: 100%;
      height: 100%;
      margin-top: 30px;
      margin-bottom: 100px;
    }
    &-wrap_questrion_block {
      background: #fdfdff;
      box-shadow: 0px 0px 24px rgba(179, 189, 200, 0.15);
      border-radius: 16px;
      position: relative;
      width: 100%;
      min-height: 110px;
      margin-bottom: 20px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      &-wrap_plus_and_text {
        position: relative;
        width: 100%;
      }
      &-questrion {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 1.1rem;
        letter-spacing: 0.2px;
        color: #25282b;
        position: relative;
        display: inline-block;
        width: 80%;
        height: auto;
        left: 5%;
        padding-top: 40px;
        padding-bottom: 40px;
        &.active {
          color: #007eff;
        }
        &:hover {
          color: #007eff;
          & + .faq_page-wrap-wrap_questrion_block-plus {
            fill: #007eff;
          }
        }
      }
      &-plus {
        position: absolute;
        display: inline-block;
        width: 30px;
        height: 30px;
        left: 110%;
        top: 37%;
        @include sm {
          left: 100%;
        }
      }
      &-minus {
        position: absolute;
        display: inline-block;
        width: 30px;
        height: 30px;
        left: 110%;
        top: 37%;
        @include sm {
          left: 100%;
        }
      }
      &-response {
        display: none;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.1px;
        color: #25282b;
        opacity: 0.5;
        width: 85%;
        margin-top: 10px;
        position: relative;
        left: 5%;
        padding-bottom: 40px;
        &.active {
          display: block;
        }
      }
    }
  }
}
